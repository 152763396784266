import React from "react";
import { Form, Input, Modal, Select, notification } from "antd";
import {useNavigate} from 'react-router-dom'
import { ChangePassword } from "../services/auth";


const { Option } = Select;

export default function Forgotpassword({ open, onCancel, isEdit = false }) {
  const formRef = React.useRef();
  const navigate = useNavigate();


  const onSubmit = () => {
    formRef.current?.submit();
  };

const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };

  const onFinish = (value) => {
    navigate('/login');


    ChangePassword({emailId:value.emailId})
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  return (
    <Modal
      title={isEdit ? "Edit" : "Please provide your registered Email Id"}
      open={open}
      onOk={onSubmit}
      onCancel={_onCancel}
      okText={isEdit ? "Save" : "Send Email"}
      style={{marginTop:'200px',marginRight:'460px',textAlign:'center'}}
      maskClosable={false} // Prevent closing on outside click

    >
      <br></br>
      <Form layout="vertical" onFinish={onFinish} ref={  formRef }>
        <Form.Item
          // label="Email"
          name="emailId"
          // rules={[{ required: true, message: "Enter Email" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
