//     [2020] - [2024] Wikiworks Innovations Private Limited.
//  All Rights Reserved.

// NOTICE:  All information contained herein is, and remains
// the property of Wikiworks its suppliers,
// if any.  The intellectual and technical concepts contained
// herein are proprietary to Wikiworks
// and its suppliers and may be covered by U.S. and Foreign Patents,
// patents in process and are protected by trade secret or copyright
// law.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Wikiworks.
// """
import React, { useState } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Typography,
  Divider,
  DatePicker,
  notification,
  Checkbox,
  Modal,
  Input,
  Tooltip,
} from 'antd';
import CustomTooltip from '../../layouts/ToolTip';
import TextArea from 'antd/es/input/TextArea';
import { Approved, Approved1 } from '../../services/auth';
import { Email } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from '../../services/axiosInstance';
import useAuth from '../../hooks/useAuth';
import dayjs from 'dayjs';
import { on } from 'stream';
import { updatePass } from '../../services/auth';
import { SmileOutlined } from '@ant-design/icons';
import { color, init } from 'echarts';
import '../../style.css';
import './summaryStyle.css';
import {
  uploadDocument as uploadDocumentToGCP,
  deleteDocumentFromGcp,
} from '../../services/auth';
import { getLocalDate } from '../../utils/helperFunction';

const { Title } = Typography;

const Summarygeneration = ({
  initialValues,
  onNext,
  jobDetails,
  Summary,
  wageDetails,
  onPrev,
  imageSrc,
  setImageSrc,
  uploadDocument,
  updatedFields,
  newData,
}) => {
  const formRef = React.useRef();
  const navigate = useNavigate();
  const { _id } = useParams();
  const { id } = useParams();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { user, userConfig } = useAuth();

  const [PfDisabled, setPfDisabled] = React.useState(true);
  const [data, setData] = React.useState(initialValues);

  console.log('dataaa72', data);
  console.log('jobData73', jobDetails);
  console.log('initialValues75 ', initialValues);

  const [jobdata, setjobData] = React.useState(jobDetails);
  const [wagedata, setwagedata] = React.useState(wageDetails);
  const [uploadDoc, setuploaddoc] = React.useState(uploadDocument);

  const date = dayjs(data.Dob);
  const formattedDate = date.format('DD-MM-YYYY');
  //-----------------------
  const date1 = dayjs(jobdata.dlExpiry);
  const dlExpiry = date1.format('DD-MM-YYYY');
  //-----------------------
  const date2 = dayjs(jobdata.doj);
  const doj = date2.format('DD-MM-YYYY');
  //-----------------------
  const date3 = dayjs(jobdata.effectiveDate);
  const effectiveDate = date3.format('DD-MM-YYYY');

  //-----------------
  const date4 = dayjs(jobdata.subSkillDate);
  const subSkillDate = date4.format('DD-MM-YYYY');

  const locationId = data?.locationId;
  const orgId = data?.orgId;

  console.log('userConfig   ', userConfig?.level);

  const base64ToBlob = (base64String, mimeType) => {
    // Remove the base64 data URL prefix if it exists
    const base64 = base64String.split(';base64,').pop();

    // Decode the base64 string into a binary string
    const binaryString = atob(base64);

    // Create a typed array (Uint8Array) to store the binary data
    const byteArray = new Uint8Array(binaryString.length);

    // Convert the binary string into the byte array
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    // Convert the byte array into a Blob
    const blob = new Blob([byteArray], { type: mimeType });

    return blob; // This is your "buffer" in Blob form
  };

  const update = async () => {
    initialValues.Dob = dayjs(initialValues?.Dob)
      .utc()
      .local()
      .startOf('day')
      .format('YYYY-MM-DD');
    initialValues.dob = initialValues?.dob
      ? dayjs(initialValues?.dob)
          .utc()
          .local()
          .startOf('day')
          .format('YYYY-MM-DD')
      : '';
    jobdata.doj = dayjs(jobdata?.doj)
      .utc()
      .local()
      .startOf('day')
      .format('YYYY-MM-DD');
    jobdata.dlExpiry = jobdata.dlExpiry ? getLocalDate(jobdata?.dlExpiry) : '' 

    let file = '';
    let profilePicture = '';
    let imageBuffer;
    const mimeType = 'image/jpeg';

    //delete previously uploaded file on GCP
    if (data?.livePhoto?.includes('data')) {
      handleImageProcessing(data.livePhoto, data.livePhotoGcpPath);
    } else if (data?.uploadPhoto?.fileList?.[0]?.thumbUrl?.includes('data')) {
      handleImageProcessing(
        data.uploadPhoto.fileList[0].thumbUrl,
        data.uploadPhotoGcpPath
      );
    } else {
      file = null;
    }

    // Helper function to process images
    function handleImageProcessing(imageSource, gcpPath) {
      imageBuffer = base64ToBlob(imageSource, mimeType);
      file = imageBuffer;
      if (gcpPath) {
        deleteDocumentFromGcp(gcpPath);
      }
    }

    if (file) {
      console.log('inside file');
      const fileName = `${data?.mobile}_${
        userConfig?.orgId
      }_${Date.now()}_profilePicture.png`;
      const filePath = `${process.env.REACT_APP_GCP_PATH}/${userConfig?.orgId}/contract_workmen/${fileName}`;

      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', filePath);

      const res = await uploadDocumentToGCP(formData);
      profilePicture = res?.data?.data?.gcsFileName;
      console.log(res?.data?.data?.gcsFileName, 'res?.data?.data?.gcsFileName');
    } else {
      profilePicture = data?.livePhotoGcpPath
        ? data?.livePhotoGcpPath
        : data?.uploadPhotoGcpPath
        ? data?.uploadPhotoGcpPath
        : '';
    }

    // Check if newData is not empty

    if (Object.keys(newData).length !== 0) {
      const updatedEpnRenewHistory = [
        ...initialValues.epnRenewHistory,
        newData,
      ];
      const updatedEpnRenewDetails = {
        ...initialValues,
        epnRenewHistory: updatedEpnRenewHistory,
      };
      let passDetails = {
        personalDetails: updatedEpnRenewDetails,
        jobDetails: jobdata,
        wageDetails: wagedata,
        locationId: locationId,
        uploadDocument: uploadDoc,
      };
      if (data?.livePhoto) {
        passDetails = {
          ...passDetails,
          personalDetails: {
            ...passDetails?.personalDetails,
            uploadPhoto: null,
            livePhoto: null,
            livePhotoGcpPath: profilePicture,
          },
        };
      }
      if (data?.uploadPhoto) {
        passDetails = {
          ...passDetails,
          personalDetails: {
            ...passDetails?.personalDetails,
            uploadPhoto: null,
            livePhoto: null,
            uploadPhotoGcpPath: profilePicture,
          },
        };
      }
      // else{
      //   passDetails = {
      //     ...passDetails,
      //     personalDetails: {
      //       ...passDetails?.personalDetails,
      //       aadharPhoto: null,
      //       aadharPhotoGcpPath: profilePicture,
      //     }
      //   };
      // }
      // const passDetails = {
      //   personalDetails: updatedEpnRenewDetails,
      //   jobDetails: jobdata,
      //   wageDetails: wagedata,
      //   locationId: locationId,
      //   livePhoto: imageSrc,
      //   profilePicture: profilePicture,
      //   uploadDocument: uploadDoc,
      // };
      /* Integration of CLMS with VYN for contract workman onboarding. @ Viresh Bangargi In Progress */

      updatePass(id, passDetails)
        .then((res) => {
          notification.success({ message: 'Record updated Successfully' });
        })
        .catch((err) => {
          notification.error({ message: err.message });
        });
      navigate('/home/supervisor/employee');
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      Email({
        // medicalCheckupDate: values.medicalCheckupDate,
        workmenName: data.firstName + ' ' + data.lastName,
        //templateId: 'MEDICAL_TEST_SCHEDULED',
        templateId: 'UPDATE_CONTRACT_WORKMAN_PROFILE',
        // VYNApplication: process.env.REACT_APP_URL,
        VYNApplication:`${axios.defaults.baseURL.replace('/api', '')}/login`, 
        orgId: userConfig.orgId,
        level: 1,
        role: 'approver',
        locationId: passDetails.locationId,
        empCode: data.empCode,
      })
        .then((res) => {
          notification.success({ message: res.data.message });
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
        });
    } else {
      let passDetails = {
        personalDetails: initialValues, // Keep the initial values without updating epnRenewHistory
        jobDetails: jobdata,
        wageDetails: wagedata,
        locationId: locationId,
        uploadDocument: uploadDoc,
      };
      if (data?.livePhoto) {
        passDetails = {
          ...passDetails,
          personalDetails: {
            ...passDetails?.personalDetails,
            livePhoto: null,
            livePhotoGcpPath: profilePicture,
          },
        };
      }
      if (data?.uploadPhoto) {
        passDetails = {
          ...passDetails,
          personalDetails: {
            ...passDetails?.personalDetails,
            uploadPhoto: null,
            uploadPhotoGcpPath: profilePicture,
          },
        };
      }
      // else{
      //   passDetails = {
      //     ...passDetails,
      //     personalDetails: {
      //       ...passDetails?.personalDetails,
      //       aadharPhoto: null,
      //       aadharPhotoGcpPath: profilePicture,
      //     }
      //   };
      // }
      // const passDetails = {
      //   personalDetails: initialValues, // Keep the initial values without updating epnRenewHistory
      //   jobDetails: jobdata,
      //   wageDetails: wagedata,
      //   locationId: locationId,
      //   livePhoto: imageSrc,
      //   profilePicture: profilePicture,
      //   uploadDocument: uploadDoc,
      // };

      updatePass(id, passDetails)
        .then((res) => {
          notification.success({ message: 'Record updated Successfully' });
        })
        .catch((err) => {
          notification.error({ message: err.message });
        });
      navigate('/home/supervisor/employee');
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      Email({
        // medicalCheckupDate: values.medicalCheckupDate,
        workmenName: data.firstName + ' ' + data.lastName,
        //templateId: 'MEDICAL_TEST_SCHEDULED',
        templateId: 'UPDATE_CONTRACT_WORKMAN_PROFILE',
        // VYNApplication: process.env.REACT_APP_URL,
        VYNApplication:`${axios.defaults.baseURL.replace('/api', '')}/login`, 
        orgId: userConfig.orgId,
        level: 1,
        role: 'approver',
        locationId: passDetails.locationId,
        empCode: data.empCode,
      })
        .then((res) => {
          notification.success({ message: res.data.message });
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
        });
    }
  };

  const onFinish = (values) => {
    navigate('/home/approver/list');

    const data1 = {
      id: data.id,
      medicalCheckupDate: values.medicalCheckupDate,
      reason: values.reason,
      status: 'approved',
      designation: jobdata.designation,
      approvers: data.approvers,
      exceptions: data.exceptions,
      isTransferCase: data.isTransferCase,
      uploadDocument: uploadDoc,
    };
    if (initialValues?.statusToBeImposed) {
      data1.initialStatusToBeImposed = initialValues?.initialStatusToBeImposed;
      data1.statusToBeImposed = initialValues?.statusToBeImposed;
      data1.blockType = initialValues?.blockType;
      data1.statusToDate = initialValues?.statusToDate;
      data1.statusFromDate = initialValues?.statusFromDate;
      data1.remark = initialValues?.remark;
      data1.statusTerminateNoticeDate =
        initialValues?.statusTerminateNoticeDate;
    }

    onNext(values);
    Approved(data1)
      .then((res) => {
        notification.success({ message: res?.data?.message });
      })
      .catch((err) => {
        notification.error({ message: err?.res?.data?.message });
      });
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    Email({
      medicalCheckupDate: values.medicalCheckupDate,
      workmenName: data.firstName + ' ' + data.lastName,
      //templateId: 'MEDICAL_TEST_SCHEDULED',
      templateId: 'WORKMEN_APPROVED_FOR_SAFETY_TEST',
      // VYNApplication: process.env.REACT_APP_URL,
      VYNApplication:`${axios.defaults.baseURL.replace('/api', '')}/login`, 
      orgId: userConfig.orgId,
      level: 2,
      role: userConfig.role,
      locationId: userConfig.locationId,
      empCode: data.empCode,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };
  const Reject = () => {
    navigate('/home/approver/list');
    const data = {
      id: id,
      medicalCheckupDate: '24-03-2023',
      status: 'rejected',
      reason: rejectReason,
    };
    Approved1(data)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    Email({
      templateId: 'RECORD_RESUBMITTED',
      workmenName: userConfig.firstName + ' ' + userConfig.lastName,
      // VYNApplication: 'https://demo.wikiworks.in/login',
      // VYNApplication: process.env.REACT_APP_URL,
      VYNApplication:`${axios.defaults.baseURL.replace('/api', '')}/login`, 
      orgId: userConfig.orgId,
      level: userConfig.level,
      role: userConfig.role,
      locationId: userConfig.locationId,
      correctRecipientEmail: userConfig.emailId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  const downloadFile = async (evt) => {
    evt.preventDefault();

    let status = data.status;
    if (
      status === 'Suspended' ||
      status === 'Terminated' ||
      status === 'recoverloss' ||
      status === 'warning' ||
      status === 'ActiontakenforIncident'
    ) {
      navigate('/home/supervisor/employee');

      notification.open({
        message: 'Unable to Download Pass',
        description: `Workmen profile is ${status}.`,
        icon: (
          <SmileOutlined
            style={{
              color: '#108ee9',
            }}
          />
        ),
      });
    } else {
      navigate('/home/supervisor/employee');

      axios
        .post('/pass/pdf', { id: id }, { responseType: 'arraybuffer' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Token Pass.pdf');
          document.body.appendChild(link);
          link.click();
        });
    }
  };

  let status = data.status;

  const Pftoggle = () => {
    setPfDisabled(!PfDisabled);
  };

  // for Rejection Feedback
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [isOkButtonDisabled, setIsOkButtonDisabled] = useState(true);

  React.useEffect(() => {
    // Enable the OK button only if rejectReason length is greater than 0
    setIsOkButtonDisabled(rejectReason.length === 0);
  }, [rejectReason]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    Reject(rejectReason);

    // Close the modal
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    // Close the modal without taking any action
    setIsModalVisible(false);
  };

  // const span{
  //   'MarginLeft': '10px' ,/* Override existing margin */
  //   'PaddingLeft': 5px /* Override padding */
  // }

  const getRowClassName = (fieldName) => {
    return updatedFields[fieldName] ? 'row-highlight' : '';
  };
  console.log(initialValues, 'init 458');
  return (
    <div>
      <Form initialValues={initialValues} ref={formRef} onFinish={onFinish}>
        <Row class='row'>
          <Col span={12} class='column'>
            <Title level={5} style={{ color: 'red' }}>
              Personal Details
            </Title>
            <Row className={getRowClassName('firstName')}>
              <span>First Name</span>
              <span class='span2'>:</span>
              <CustomTooltip title={data.firstName}>
                {data.firstName}
              </CustomTooltip>
            </Row>
            <Row className={getRowClassName('middleName')}>
              <span>Middle Name</span>
              <span class='span2'>:</span>
              <CustomTooltip title={data.middleName}>
                {data.middleName}
              </CustomTooltip>
            </Row>
            <Row className={getRowClassName('lastName')}>
              <span>Last Name</span>
              <span class='span2'>:</span>
              <CustomTooltip title={data.lastName}>
                {data.lastName}
              </CustomTooltip>
            </Row>
            {/* <Row className={getRowClassName('longName')}>
              <span>Long Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.ongName}</span>
            </Row> */}
            <Row className={getRowClassName('gender')}>
              <span>Gender</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.gender}</span>
            </Row>
            <Row className={getRowClassName('Dob')}>
              <span>Date Of Birth</span>
              <span class='span2'>:</span>
              <span class='span3'>{formattedDate}</span>
            </Row>
            <Row className={getRowClassName('age')}>
              <span>Age</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.age}</span>
            </Row>
            <Row className={getRowClassName('mobile')}>
              <span>Mobile Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.mobile}</span>
            </Row>
            {/* <Row>
              <span>Aadhaar Number</span>
              <span class="span2">:</span>
              <span class="span3">{data.aadharNo}</span>
            </Row> */}
            <Row className={getRowClassName('aadharNo')}>
              <span>Aadhaar Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{`XXXX XXXX ${`${data.aadharNo}`.slice(
                -4
              )}`}</span>
            </Row>
            {/* <Row>
              <span>PAN</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pan}</span>
            </Row>
            <Row>
              <span>Ration Card Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.rationcardno}</span>
            </Row>{' '} */}
            <Row className={getRowClassName('votercardno')}>
              <span>Voter Card Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.votercardno}</span>
            </Row>
            <Row>
              <span>Handicapped</span>
              <span class='span2'>:</span>
              <span class='span3'>{data?.disabilityType ? 'Yes' : 'No'}</span>
            </Row>
            <Row>
              <span>Disability Type</span>
              <span class='span2'>:</span>
              <span class='span3'>{data?.disabilityType}</span>
            </Row>
            <Row>
              <span>Disability Percentage</span>
              <span class='span2'>:</span>
              <span class='span3'>{data?.disabilityPercentage}</span>
            </Row>
            <Row className={getRowClassName('uan')}>
              <span>UAN</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.uan}</span>
            </Row>
            <Row className={getRowClassName('idmark')}>
              <span>ID Mark</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.idmark}</span>
            </Row>
            <Row className={getRowClassName('fatherName')}>
              <span>Father/Guardian's Name</span>
              <span class='span2'>:</span>
              {/* <Tooltip
                title={data.fatherName}
                placement="topLeft"
                overlayStyle={{
                  whiteSpace: "normal",
                  maxWidth: "400px", // Adjust width as necessary
                  zIndex: 1050, // Ensure it appears above other elements
                }}
                overlayInnerStyle={{
                  backgroundColor: '#ffffff', // White background
                  color: '#000000', // Black text
                  fontWeight: 400, // Optional for better readability
                  border: '1px solid #d9d9d9', // Optional border for better appearance
                  borderRadius: '4px', // Optional for rounded edges
                }}
                getPopupContainer={(trigger) => trigger.parentNode} // Tooltip container stays scoped
              >
                <span className="span3">{data.fatherName}</span>
              </Tooltip> */}
              <CustomTooltip title={data.fatherName}>
                {data.fatherName}
              </CustomTooltip>
            </Row>
            {/* <Row>
              <span>Marital Status</span>
              <span class="span2">:</span>
              <span class="span3">{data.martialStatus}</span>
            </Row> */}
            {/* <Row className={getRowClassName('spousename')}>
              <span>Spouse's Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.spousename}</span>
            </Row>
            <Row className={getRowClassName('childrenNo')}>
              <span>No.Of.Children</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.childrenNo}</span>
            </Row> */}
            <Row className={getRowClassName('emergencyContactName')}>
              <span>Emergency Contact Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.emergencyContactName}</span>
            </Row>
            <Row className={getRowClassName('emergencyContactNo')}>
              <span>Emergency Contact Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.emergencyContactNo}</span>
            </Row>
            <Row>
              <span>PF Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pfAccountNo ? 'No' : 'Yes'}</span>
            </Row>
            <Row className={getRowClassName('pfAccountNo')}>
              <span>PF Account Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pfAccountNo}</span>
            </Row>
            <Row>
              <span>ESIC/WC Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.esicno ? 'No' : 'Yes'}</span>
            </Row>
            <Row className={getRowClassName('esicno')}>
              <span>ESIC/WC Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.esicno}</span>
            </Row>
            <Row>
              <span>UAN Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.uan ? 'No' : 'Yes'}</span>
            </Row>
            <Row className={getRowClassName('uan')}>
              <span>UAN</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.uan}</span>
            </Row>
            <Row className={getRowClassName('unitName')}>
              <span>Unit Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.unitName}</span>
            </Row>
            <Row className={getRowClassName('empCode')}>
              <span>Workmen Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.empCode}</span>
            </Row>
            <Row className={getRowClassName('locationId')}>
              <span>Location Id</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.locationId}</span>
            </Row>
            <Row className={getRowClassName('policeVerification')}>
              <span>Police Verification</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.policeVerification}</span>
            </Row>
            <Row className={getRowClassName('gateNumber')}>
              <span>Gate Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.gateNumber}</span>
            </Row>
            {/* <Row className={getRowClassName('safetyTrainingFromDate')}>
              <span>Safety Training From Date</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.safetyTrainingFromDate
                  ? data.safetyTrainingFromDate.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row> */}
            {/* <Row>
              <span>Safety Training Upto Date</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.safetyTrainingUptoDate
                  ? data.safetyTrainingUptoDate.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row> */}
            {/* <Row>
              <span>Safety Training Remarks</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.safetyTrainingRemarks}</span>
            </Row> */}
            <Row className={getRowClassName('epnNumber')}>
              <span>EPN Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.epnNumber}</span>
            </Row>
            {/* <Row className={getRowClassName('status')}>
              <span>Status</span>
              <span class='span2'>:</span>
              <span class='span3'>{data?.status}</span>
            </Row> */}
            <Row className={getRowClassName('religion')}>
              <span>Religion</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.religion}</span>
            </Row>
            <Row className={getRowClassName('technicalDetails')}>
              <span>Technical Details</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.technicalDetails}</span>
            </Row>
            {/* <Row className={getRowClassName('natureOfWork')}>
              <span>Type Of Contract</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.natureOfWork}</span>
            </Row> */}
            {/* <Row className={getRowClassName('statusToBeImposed')}>
              <span>Employement Status</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.statusToBeImposed ? data.statusToBeImposed : 'Active'}
              </span>
            </Row> */}
            <Title level={5}>Aadhaar Address</Title>
            <Row className={getRowClassName('address1')}>
              <Col>Permanent Address Line 1</Col>
              <span class='span2'>:</span>
              <span class='span3'>{data.address1}</span>
            </Row>
            <Row className={getRowClassName('address2')}>
              <span>Permanent Address Line 2</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.address2}</span>
            </Row>
            <Row className={getRowClassName('address3')}>
              <span>Permanent Address Line 3</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.address3}</span>
            </Row>
            {/* <Row>
              <span>Address-2</span>
              <span class='span2'>:</span>
              <span class='span3'></span>
            </Row>
            <Row>
              <span>Address-3</span>
              <span class='span2'>:</span>
              <span class='span3'></span>
            </Row>
            <Row>
              <span>Address-4</span>
              <span class='span2'>:</span>
              <span class='span3'></span>
            </Row>
            <Row>
              <span>Address-5</span>
              <span class='span2'>:</span>
              <span class='span3'></span>
            </Row> */}
            <Row className={getRowClassName('village')}>
              <span>Village</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.village}</span>
            </Row>
            <Row className={getRowClassName('district')}>
              <span>District</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.district}</span>
            </Row>
            <Row className={getRowClassName('state')}>
              <span>State</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.state}</span>
            </Row>
            <Row className={getRowClassName('pincode')}>
              <span>Pincode</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pincode}</span>
            </Row>
            <Row className={getRowClassName('taluka')}>
              <span>Taluka</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.taluka}</span>
            </Row>
            <Row className={getRowClassName('tehsil')}>
              <span>Tehsil</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.tehsil}</span>
            </Row>
            <Row className={getRowClassName('city')}>
              <span>City</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.city}</span>
            </Row>
            <Row className={getRowClassName('country')}>
              <span>Country</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.country}</span>
            </Row>
            <Title level={5}>Present Address</Title>
            <Row className={getRowClassName('paddress1')}>
              <Col>Present Address Line 1</Col>
              <span class='span2'>:</span>
              <span class='span3'>{data.paddress1}</span>
            </Row>
            <Row className={getRowClassName('paddress2')}>
              <span>Present Address Line 2</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.paddress2}</span>
            </Row>
            <Row className={getRowClassName('paddress3')}>
              <span>Present Address Line 3</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.paddress3}</span>
            </Row>

            <Row className={getRowClassName('pvillage')}>
              <span>Village</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pvillage}</span>
            </Row>
            <Row className={getRowClassName('pdistrict')}>
              <span>District</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pdistrict}</span>
            </Row>
            <Row className={getRowClassName('pstate')}>
              <span>State</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pstate}</span>
            </Row>
            <Row className={getRowClassName('ppincode')}>
              <span>Pincode</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.ppincode}</span>
            </Row>
            <Row className={getRowClassName('ptaluka')}>
              <span>Taluka</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.ptaluka}</span>
            </Row>
            <Row className={getRowClassName('ptehsil')}>
              <span>Tehsil</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.ptehsil}</span>
            </Row>
            <Row className={getRowClassName('pcity')}>
              <span>City</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pcity}</span>
            </Row>
            <Row className={getRowClassName('pcountry')}>
              <span>Country</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pcountry}</span>
            </Row>
            {/* <hr /> */}
            {/* <Title level={5} style={{ color: 'red' }}>
              Wage Details
            </Title>
            <Row className={getRowClassName('basic')}>
              <span>Basic</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.basic}</span>
            </Row>
            <Row className={getRowClassName('da')}>
              <span>DA</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.da}</span>
            </Row>
            <Row className={getRowClassName('hra')}>
              <span>HRA</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.hra}</span>
            </Row>
            <Row className={getRowClassName('conveyance')}>
              <span>Conveyance</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.conveyance}</span>
            </Row>
            <Row className={getRowClassName('specialAllowance')}>
              <span>Special Allowance</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.specialAllowance}</span>
            </Row>
            <Row className={getRowClassName('medicalAllowance')}>
              <span>Medical Allowance</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.medicalAllowance}</span>
            </Row>
            <Row className={getRowClassName('eduAllowance')}>
              <span>Education Allowance</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.eduAllowance}</span>
            </Row>{' '}
            <Row className={getRowClassName('travelAllowance')}>
              <span>Leave/Travel Allowance</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.travelAllowance}</span>
            </Row>
            <Row className={getRowClassName('effectiveDate')}>
              <span>Effective Date</span>
              <span class='span2'>:</span>
              <span class='span3'>{effectiveDate}</span>
            </Row> */}
          </Col>

          <Col span={12} class='column2' style={{ paddingLeft: '50px' }}>
            <Title level={5} style={{ color: 'red' }}>
              Job Details
            </Title>
            <Row className={getRowClassName('orgId')}>
              <span>Principal Employer Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{userConfig.orgId}</span>
            </Row>
            <Row className={getRowClassName('supplierId')}>
              <span>Contractor Code</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {userConfig.supplierId || data?.contractorCode}
              </span>
            </Row>
            <Row className={getRowClassName('contractorName')}>
              <span>Contractor Name</span>
              <span class='span2'>:</span>
              <CustomTooltip title={jobdata?.contractorName}>
                {jobdata?.contractorName ||
                  sessionStorage.getItem('contractorName')}
              </CustomTooltip>
            </Row>
            <Row className={getRowClassName('workingArea')}>
              <span>Working Area</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.workingArea}</span>
            </Row>
            <Row className={getRowClassName('departmentCode')}>
              <span>Department Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.departmentCode}</span>
            </Row>
            {/* <Row className={getRowClassName('sectionCode')}>
              <span>Section Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.sectionCode}</span>
            </Row> */}
            {/* <Row className={getRowClassName('workorderCode')}>
              <span>Work Order Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.workorderCode}</span>
            </Row>{' '} */}
            {/* <Row className={getRowClassName('itemServiceCode')}>
              <span>Item Service Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.itemServiceCode}</span>
            </Row> */}
            <Row className={getRowClassName('employeeType')}>
              <span>Worker Category</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.workerCategory}</span>
            </Row>
            {/* <Row className={getRowClassName('labourType')}>
              <span>Labour Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.labourType}</span>
            </Row> */}
            <Row className={getRowClassName('jobCode')}>
              <span>Job Order Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.jobOrderNumber}</span>
            </Row>
            <Row className={getRowClassName('skillCode')}>
              <span>Skill Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.skillCode}</span>
            </Row>{' '}
            {/* <Row className={getRowClassName('subSkillCode')}>
              <span>Sub Skill Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.subSkillCode}</span>
            </Row>{' '} */}
            <Row className={getRowClassName('designation')}>
              <span>Designation</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.designation}</span>
            </Row>{' '}
            {/* <Row className={getRowClassName('contractorReferenceNumber')}>
              <span>Contractor Reference Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.contractorReferenceNumber}</span>
            </Row>{' '}
            <Row>
              <span>SubSkill Date</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.subSkillDate
                  ? jobdata.subSkillDate.format('DD-MM-YYYY')
                  : ''}
              </span>{' '}
            </Row>{' '} */}
            <Row className={getRowClassName('doj')}>
              <span>Date Of Joining</span>
              <span class='span2'>:</span>
              <span class='span3'>{doj}</span>
            </Row>
            {/* <Row>
              <span>Employment status</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.employmentStatus === undefined ? 'No' : 'Yes'}
              </span>
            </Row> */}
            {/* <Row>
              <span>Date of termination</span>
              <span class="span2">:</span>
              <span class="span3"></span>
            </Row> */}
            {/* <Row className={getRowClassName('terminationReason')}>
              <span>Reason for termination</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.terminationReason}</span>
            </Row> */}
            {/* <Title level={5}>Work Experience</Title>
            <Row className={getRowClassName('totalExperience')}>
              <span>Total experience</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.totalExperience}</span>
            </Row>
            <Row className={getRowClassName('previousOrg')}>
              <span>Previous Organization</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.previousOrg}</span>
            </Row>
            <Row className={getRowClassName('referredBy')}>
              <span>Referred by</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.referredBy}</span>
            </Row>
            <Title level={5}>Relative working in Company?</Title> */}
            {/* <Row>
              <span>Is Working?</span>
              <span>:</span>
              <span class='span3'>
                {jobdata.isworking === undefined ? 'No' : 'Yes'}
              </span>
            </Row>
            <Row className={getRowClassName('name')}>
              <span>Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.name}</span>
            </Row>
            <Row className={getRowClassName('mobileNo')}>
              <span>Mobile Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.mobileNo}</span>
            </Row> */}
            <Title level={5}>Other Details</Title>
            {/* <Row className={getRowClassName('shoeSize')}>
              <span>Shoe Size</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.shoeSize}</span>
            </Row> */}
            <Row className={getRowClassName('bloodGroup')}>
              <span>Blood Group</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.bloodGroup}</span>
            </Row>
            <Row className={getRowClassName('academicQualification')}>
              <span>Academic Qualification</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.academicQualification}</span>
            </Row>
            <Row className={getRowClassName('techQualification')}>
              <span>Technical Qualification</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.techQualification}</span>
            </Row>
            <Row className={getRowClassName('dl')}>
              <span>Driving Licence </span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.dl}</span>
            </Row>
            <Row className={getRowClassName('dlExpiry')}>
              <span>Driving Licence Expiry</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dlExpiry ? jobdata.dlExpiry.format('DD-MM-YYYY') : ''}
              </span>
            </Row>
            {/* <Row>
              <span>Safety Training</span>
              <span class="span2">:</span>
              <span class="span3"></span>
            </Row> */}
            <Title level={5}>Bank Details</Title>
            <Row className={getRowClassName('bankname')}>
              <span>Bank Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.bankname}</span>
            </Row>
            <Row className={getRowClassName('AccountNo')}>
              <span>Account Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.AccountNo}</span>
            </Row>
            <Row className={getRowClassName('ifsc')}>
              <span>IFSC Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.ifsc}</span>
            </Row>
            {/* <Row>
                          <span>Reject Reason</span>
                          <span class='span2'>:</span>
                          <span class='span3'>{initialValues?.reason}</span>
              </Row> */}
            {/*
            <Row className={getRowClassName('userName')}>
              <span>Name as per Bank Account</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.userName}</span>
            </Row> */}
          </Col>
        </Row>
        <Divider />

        {/* {status === "pending" ? (
          <Row>
            <Col span={6}>
              <Form.Item>
                <Checkbox onClick={Pftoggle} size="large">
                  Medical Test Date
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="medicalCheckupDate">
                <DatePicker
                  size="large"
                  placeholder="Medical Test Date"
                  disabled={PfDisabled}
                  style={{ width: "80%" }}
                  format="DD-MM-YYYY"
                  
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item style={{ float: "right", marginLeft: "10px" }}>
                <Button type="primary" htmlType="submit">
                  Approve
                </Button>
                <Button type="primary" onClick={evt => update(evt)} style={{marginLeft:"30px"}}>
                  Update
                </Button>
               
              </Form.Item>
              <Form.Item style={{ float: "right", marginLeft: "10px" }}>
              <Button style={{  marginRight: "10px" }} onClick={() => onPrev(formRef.current?.getFieldsValue())}>
                  Back
                </Button>
                <Button onClick={evt => Reject(evt)}>Reject</Button>
               

              </Form.Item>
            </Col>
            
            <Row>
              <Form.Item name="reason">
                <TextArea
                  style={{ width: "250px" }}
                  placeholder="Enter FeedBack"
                ></TextArea>
              </Form.Item>
            </Row>
          </Row>
        ) : (
          <Row>
            <Col span={12}>
             <Title level={5}>Approver List</Title>
                <Row>{data.approvedBy[0]}</Row>
                <Row>{data.approvedBy[1]}</Row>            
                <Row>{data.approvedBy[2]}</Row>             
            
                </Col>
            <Col span={12}>
              <Form.Item style={{ float: "right", marginLeft: "10px" }}>
                <Button type="primary" onClick={evt => downloadFile(evt)}>Download Pass</Button>
              </Form.Item>
              <Form.Item style={{ float: "right", marginLeft: "10px" }}>
          <Button
            onClick={() => onPrev(formRef.current?.getFieldsValue())}
            type="primary"
          >
            Prev
          </Button>
        </Form.Item>
            </Col>
          </Row>
        )} */}

        {userConfig.role === 'supervisor' && (
          <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
            <Button
              style={{ marginRight: '10px' }}
              onClick={() => onPrev(formRef.current?.getFieldsValue())}>
              Back
            </Button>
            <Button
              type='primary'
              onClick={(evt) => update(evt)}
              style={{ marginLeft: '30px' }}>
              Update
            </Button>
          </Form.Item>
        )}
        {/* {((data.Edited === 'Yes' && userConfig.role === 'approver') ||
          (data.approvalStage === 0 && userConfig.role === 'approver') ||
          (data.approvalStage == '3' &&
            userConfig.role == 'approver' &&
            userConfig.level == 1)) && ( */}

          {/* Sachin code */}

        {/* {((data.Edited === 'Yes' &&
          userConfig.role === 'approver' &&
          data.status !== 'rejected') ||
          (data.approvalStage === 0 &&
            userConfig.role === 'approver' &&
            data.status !== 'rejected') ||
          (data.approvalStage == '3' &&
            initialValues?.statusToBeImposed &&
            userConfig.role === 'approver') ||
          (userConfig.role === 'approver' && data.status == 'pending')) &&
          userConfig.level !== 3 &&
          userConfig?.level !== 2 && ( */}

          {((data.Edited === 'Yes' &&
          userConfig.role === 'approver' &&
          data.status !== 'rejected') ||
          (data.approvalStage === 0 &&
            userConfig.role === 'approver' &&
            data.status !== 'rejected') ||
          (data.approvalStage == '3' &&
            initialValues?.statusToBeImposed &&
            userConfig.role === 'approver')) &&
          userConfig?.level != 3 &&
          userConfig?.level != 2 && (
            <Row>
              <Col span={6}>
                {/* <Form.Item>
                <Checkbox onClick={Pftoggle} size='large'>
                  Medical Test Date
                </Checkbox>
              </Form.Item> */}
              </Col>
              <Col span={6}>
                {/* <Form.Item name='medicalCheckupDate'>
                <DatePicker
                  size='large'
                  placeholder='Medical Test Date'
                  disabled={PfDisabled}
                  style={{ width: '80%' }}
                  format='DD-MM-YYYY'
                />
              </Form.Item> */}
              </Col>

              <Col span={12}>
                <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
                  <Button type='primary' htmlType='submit' hidden={initialValues?.status === initialValues?.statusToBeImposed}>
                    Approve
                  </Button>
                </Form.Item>
                <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
                  <Button
                    style={{ marginRight: '10px' }}
                    onClick={() => onPrev(formRef.current?.getFieldsValue())}>
                    Back
                  </Button>
                  <Button onClick={showModal} hidden={initialValues?.status === initialValues?.statusToBeImposed}>Reject</Button>

                  <Modal
                    title='Reject Reason'
                    visible={isModalVisible}
                    onOk={handleOk}
                    maskClosable={false} // Prevent closing on outside click
                    onCancel={handleCancel}
                    okButtonProps={{ disabled: isOkButtonDisabled }}>
                    <Input
                      placeholder='Enter reason for rejection'
                      value={rejectReason}
                      onChange={(e) => setRejectReason(e.target.value)}
                    />
                  </Modal>
                </Form.Item>
              </Col>

              <Row>
                {/* <Form.Item name='reason'>
                <TextArea
                  style={{ width: '250px' }}
                  placeholder='Enter FeedBack'></TextArea>
              </Form.Item> */}
              </Row>
            </Row>
          )}

        {
          // (data.autoApproved ||
          //   ((data.approvalStage === 2 || data.approvalStage === 3) &&
          //     userConfig.role === 'supervisor')) && (
          data.status == 'approved' ||
            (data.status == 'rehired' && (
              <Row>
                <Col span={12}>
                  {/* <Title level={5}>Approver List</Title>
              {data.approvedBy &&
                data.approvedBy.length &&
                data?.approvedBy.map((approver, index) => {
                  return <Row key={index}>{approver}</Row>;
                })} */}

                  <Form.Item style={{ float: 'right', marginLeft: '15px' }}>
                    <Button type='primary' onClick={(evt) => downloadFile(evt)}>
                      Download Pass
                    </Button>
                  </Form.Item>
                </Col>
                {/* <Col span={12}>
              <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
                <Button type='primary' onClick={(evt) => downloadFile(evt)}>
                  Download Pass
                </Button>
              </Form.Item>
              <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
                <Button
                  onClick={() => onPrev(formRef.current?.getFieldsValue())}
                  type='primary'>
                  Prev
                </Button>
              </Form.Item>
            </Col>  */}
              </Row>
            ))
        }
      </Form>
    </div>
  );
};

export default Summarygeneration;

// const data =[{"name":"test1"},{"name":"test2"}];
// return (
//   <div>
//   {data.map(function(d, idx){
//      return (<li key={idx}>{d.name}</li>)
//    })}
//   </div>
// );
// }
