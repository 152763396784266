import React, { useState, useEffect } from 'react';
import {
  Button,
  Upload,
  message,
  notification,
  Row,
  Col,
  Form,
  Typography,
  Select,
  Modal,
  Table,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import useAuth from '../../hooks/useAuth';
import { uploadBulkDataApi } from '../../services/auth';

import axios1 from '../../services/axiosInstance';
import dayjs from 'dayjs';
import { setLoader } from '../../actions';
const XLSX = require('xlsx');
const { Title } = Typography;
const { Option } = Select;

const BulkUpload = () => {
  const [fileList, setFileList] = useState([]);
  const [location, setLocation] = useState({});
  const [Employee, setEmployee] = useState([]);

  const navigate = useNavigate();
  const { user, userConfig } = useAuth();

  useEffect(() => {
    EmployeeList1();
  }, []);

  const EmployeeList1 = async () => {
    const Response = await axios1.get('/org/locations');
    const Response2 = Response.data.org.locations;

    setLocation({ Response2 });
  };

  const handleFileChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1); // Allow only one file
    setFileList(fileList);
  };

  const formatField = (item, locationId) => {
    const dob = dayjs(item['DOB'], 'DD/MM/YYYY').format(
      'YYYY-MM-DDTHH:mm:ss.SSSZ'
    );
    // const doj = dayjs(item['Doj'], 'DD/MM/YYYY').format(
    //   'YYYY-MM-DDTHH:mm:ss.SSSZ'
    // );
    const age = calculateAge(dob);

    const personalDetails = {
      LongName: item['Long Name'],
      firstName: item['First Name'],
      lastName: item['Last Name'],
      initials: item['Initials'],
      gender: item['Gender'],
      Dob: dayjs(item['Date of Birth'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      aadharNo: item['Aadhar Number'],
      fatherName: item['Father Name'],
      dobFather: dayjs(item['DOB Of Father'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      motherName: item['Mother Name'],
      dobMother: dayjs(item['DOB Of Mother'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      healthIssue: item['Health Issue'],
      extraInformation: item['Extra information'],
      //   age: age,
      age: item['Age'],

      initials: item['Initials'],
      birthPlace: item['Birth Place'],
      noOFDependents: item['No. Of Dependants'],
      noOFChildren: item['No. Of Children'],

      martialStatus: item['Marital Status'],
      spousename: item['Name of Spouse'],
      relation: item['Relation'],
      qualificationOfSpouse: item['Qualifications of Spouse'],
      occupationOfSpouse: item['Occupation of Spouse'],
      dobOFSpouse: dayjs(item['DOB of Spouse'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      dobOFMarriage: dayjs(item['Date Of Marriage'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      // dobFather: dayjs(item['DOB Of Father'], 'DD/MM/YYYY').format(
      //   'YYYY-MM-DDTHH:mm:ss.SSSZ'
      // ),
      // dobMother: dayjs(item['DOB of Mother'], 'DD/MM/YYYY').format(
      //   'YYYY-MM-DDTHH:mm:ss.SSSZ'
      // ),
      childrenNo: String(item['Child No.']),
      name: item['Child Name'],
      male: String(item['Child_Male/Female']),
      birthDate: dayjs(item['Child_BirthDate'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      medium: item['Child_Medium'],
      occupation: item['Child_Occupation'],
      mediclaim: item['Child_Mediclaim'],
      effDate: dayjs(item['Child_Mediclaim Eff date'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      religion: item['Religion'],
      //--------------
      caste: item['Caste'],
      domicile: item['Domicile (State)'],
      basedinGujarath: item['Based in Gujrat?'],
      pan: item['PAN'],
      // numberOfYearSpent: String(item['Years In Gujarat (Since when)']),
      yearsInGujarath: dayjs(
        item['Years In Gujarat (Since when)'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      disable: item['Disabled?'],
      disabilityByBirth: item['Disability by Birth or while working'],
      disabilityType: item['Disablilty Type'],
      disabilityPercentage: item['Disablilty%'],
      addressRevisedDate: dayjs(
        item['Address Revised Date'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),

      address1: item['Present Address Line 1'],
      address2: item['Present Address Line 2'],
      address3: item['Present Address Line 3'],
      village: item['Present Village/Mandal'],
      state: item['Present State'],
      district: item['Present District'],
      pincode: item['Present Pin Code'],
      mobile: item['Present Contact No.'],
      postOffice: item['Present Post Office'],
      paddress1: item['Permanent Address Line 1'],
      ppaddress2: item['Permanent Address Line 2'],
      ppaddress3: item['Permanent Address Line 3'],
      pvillage: item['Permanent Village/Mandal'],
      pmobile: item['Permanent Contact No.'],
      pstate: item['Permanent State'],
      pdistrict: item['Permanent District'],
      ppincode: item['Permanent Pin Code'],
      ppostOffice: item['Permanent Post Office'],

      //Professional Details
      empCode: item['Worker Code'],
      psNumber: item['P S Number'],

      locationId: item['Location ID'],
      // experienceDetails: item['Experience Details'],
      emailId: item['Email Id'],
      psNumber: item['PS #'],
      esicno: item['IP_ESIC_Number'],
      uan: String(item['UAN_Number']),
      pfAccountNo: item['Pf_Account_No'],
      locationId: locationId,
      emailId: item['Email Id'],
    };

    const jobDetails = {
      company: item['Company Name'],

      deptHead: item['Dept Head'],
      functionCode: item['Function Code'],
      primaryJobSkill: item['Primary Job'],
      immediateadmsupervisor: item['Immediate adm. Supervisor'],
      immediatefuncsupervisor: item['Immediate func. Supervisor'],
      nextfuncsupervisor: item['Next func. Supervisor'],
      contactSupervisor: item['Contact Supervisor'],
      location: item['Location'],
      csnCode: item['CSN Code'],
      officeTel: item['Office Tele No.'],
      transportUser: item['Transport User?'],
      cadreAtJoining: item['Cadre at Joining'],

      withCompany: item['With Company?'],
      deptartmentCode: item['Department Code'],
      currentCadre: item['Current Cadre'],
      fromCadre: item['From Cadre'],
      toCadre: item['To Cadre'],
      exTrainee: item['Ex-Trainee?'],
      // area:item['Working Area'],
      designation: item['Designation'],
      categoryCode: item['Category Code'],
      gradeYear: String(item['Grade/Training Year']),
      doj: dayjs(item['Date Of Joining'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      dateofStartingProbation: dayjs(
        item['Date of Starting Probation'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      dateofConfirmation: dayjs(
        item['Date of Confirmation'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      lastDateOfEmployement: dayjs(
        item['Last Date of Employment'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),

      shiftCode: item['Shift Code'],
      dateofRetiremenet: dayjs(item['Date of Retirement'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      lockerNo: item['Locker No.'],
      sportGroup: item['Sports Group'],
      directWorkmen: item['Direct Workman'],
      lastPromotion: dayjs(item['Last Promotion'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      supportDocument: item['Support Document'],
      currentStatus: item['Current Status'],
      appoinmentRefNo: item['Appointment Ref No'],
      exTrainee: item['Ex Trainees?'],
      area: item['Area'],

      appointmentRefDate: dayjs(
        item['Appointment Ref Date'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      trtLocation1: String(item['TRT Location (1)']),
      trtLocation2: String(item['TRT Location (2)']),
      remark: item['Remark'],
      busRouteCode: item['Bus Route Code'],
      event: String(item['Event']),
      // experience:item['Experience'],
      // effDate: dayjs(item['Eff.Date'], 'DD/MM/YYYY').format(
      //   'YYYY-MM-DDTHH:mm:ss.SSSZ'
      // ),

      joiningRoute: item['Joining Route'],
      committee: item['Committee / Post'],
      achievement: item['Achievement'],

      longServiceAward15: dayjs(
        item[' Long Service Award15'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      dateOfEvent15: dayjs(item['Date Of Event 15'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      longServiceAward20: dayjs(
        item['Long Service Award 20'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      dateOfEvent20: dayjs(item['Date Of Event 20'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      longServiceAward25: dayjs(
        item['Long Service Award 25'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      dateOfEvent25: dayjs(item['Date Of Event 25'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      longServiceAward30: dayjs(
        item['Long Service Award30'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      dateOfEvent30: dayjs(item['Date Of Event30'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      longServiceAward35: dayjs(
        item['Long Service Award 35'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      dateOfEvent35: dayjs(item['Date Of Event 35'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),

      shoeSize: item['Safety Shoe Size'],
      bloodGroup: String(item['Blood Group']),
      boilerSuitSize: item['Boiler Suit Size'],
      experience: String(item['Experience']),
      transferFromDate: dayjs(item['Transfer FROM date'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      transferToDate: dayjs(item['Transfer TO date'], 'DD/MM/YYYY').format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      ),
      academicQualification: String(item['Educational Qualification']),
    };
    const wageDetails = {
      dailywages: String(item['Daily/Monthly Wages']),
      currentBasic: String(item['Current  Basic']),
      joiningBasic: String(item['Joining Basic']),
      confirmationBasic: String(item['Confirmation  Basic']),
    };
    const uploadDocument = {
      annualMedicalCheckupDate: dayjs(
        item['Next Annual Medical Checkup Date'],
        'DD/MM/YYYY'
      ).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      documentIssuedBy: String(item['Document Issued By']),
    };

    const payload = {
      personalDetails,
      jobDetails,
      wageDetails,
      // uploadDocument,
      locationId,
      employementType: 'Permanent',
    };

    return payload;
  };

  const removeDuplicatesAndNullsByFieldsAndStructureData = (
    data,
    fields,
    locationId
  ) => {
    const seen = new Set();
    const uniqueItems = [];
    const duplicateItems = [];

    data.forEach((item) => {
      const values = fields.map((field) => item[field]);

      // Check if any of the values is null
      if (values.includes(null)) {
        return; // Skip objects with null values
      }

      const key = values.join('|');

      const formatedValue = formatField(item, locationId);

      // Check for duplicates
      if (!seen.has(key)) {
        seen.add(key);
        uniqueItems.push(formatedValue);
      } else {
        duplicateItems.push(formatedValue);
      }
    });

    return { uniqueItems, duplicateItems };
  };
  const calculateAge = (dob) => {
    const currentDate = dayjs(); // Get the current date
    const birthDate = dayjs(dob, 'YYYY-MM-DDTHH:mm:ss.SSSZ'); // Parse the DOB string into a dayjs object

    const age = currentDate.diff(birthDate, 'year'); // Calculate the difference in years

    return age;
  };

  //Aprajita Bulk Upload Code
  const onFinish = async (value) => {
    const fileInput = fileList[0];

    if (!fileInput) {
      message.error('No file uploaded');
      return;
    }

    const formdata = new FormData();
    formdata.append('file', fileInput.originFileObj, fileInput.name);
    formdata.append('locationId', userConfig.locationId);
    formdata.append('createdBy', userConfig.userid);
    formdata.append('orgId', userConfig.orgId);

    const requestOptions = {
      method: 'POST',
      headers: {
        Token: user?.token,
      },
      body: formdata,
      redirect: 'follow',
    };

    setLoader(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/uploadRegularWorkmen`,
        // 'http://localhost:3000/api/uploadRegularWorkmen',

        requestOptions
      );

      const result = await response.json();

      if (result?.duplicateAadharEntries.length > 0) {
        const formattedEntries = result?.duplicateAadharEntries?.map((row) => {
          return {
            firstName: row?.personalDetails?.firstName || 'N/A',
            lastName: row?.personalDetails?.lastName || 'N/A',
            aadharNo: row?.personalDetails?.aadharNo || 'N/A',
            // Reason: 'Duplicate Aadhaar Entry',
            length: 1, // or the desired logic for calculating length
          };
        });

        setEmployee(formattedEntries);
      } else {
        navigate('/home/ir/employee');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Aadhaar Number',
      dataIndex: 'aadharNo',
      key: 'aadharNo',
    },
    // {
    //   title: 'PF Number',
    //   dataIndex: 'pfAccountNo',
    // },
    // {
    //   title: 'ESI Number',
    //   dataIndex: 'esicno',
    // },
    // {
    //   title: 'Reason',
    //   dataIndex: 'Reason',
    // },
  ];

  const handleDownload = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/download-excel_RegularWorkmen`,

        {
          //const response = await fetch(
          // 'https://vyn.wikiworks.in:443/api/download-excel_RegularWorkmen',
          // {
          // const response = await fetch(
          // 'https://demo.wikiworks.in:443/api/download-excel_RegularWorkmen',
          // `${process.env.REACT_APP_API_URL}/download-excel_RegularWorkmen`,
          // {
          method: 'GET',
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, 'Template.xlsx');
      } else {
        console.error('Failed to download Excel sheet');
      }
    } catch (error) {
      console.error('Failed to download Excel sheet', error);
    }
  };

  const handleCancel = () => {
    setEmployee([]);
    navigate('/home/ir/employee');
  };

  return (
    <>
      <Row>
        <Title level={5} style={{ fontWeight: 'bolder' }}>
          Bulk Upload For Regular Workmen
        </Title>
      </Row>
      <Form layout='vertical' onFinish={onFinish}>
        <Row>
          <Col span={6}>
            <Form.Item name='dot' label='Download Template'>
              <Button type='primary' onClick={handleDownload}>
                Download Template
              </Button>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='locationId'
              label='Location Id'
              rules={[{ required: true, message: 'Enter LocationID' }]}>
              <Select style={{ width: '60%' }}>
                {/* {location.Response2?.slice(1).map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.id}
                  </Option>
                ))} */}
                <Option value={userConfig.locationId}>
                  {userConfig.locationId}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='Upload Bulk Documents' name='bok'>
              <Upload
                fileList={fileList}
                onChange={handleFileChange}
                beforeUpload={() => false}
                accept='.xlsx'
                rules={[{ required: true, message: 'Select Upload' }]}>
                <Button disabled={fileList.length === 1}>
                  Select Bulk Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='upload' label='Upload Bulk Data'>
              <Button
                type='primary'
                disabled={fileList.length === 0}
                htmlType='submit'>
                Upload Bulk Data
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Modal
          title='Duplicate Aadhaar Data'
          open={Employee.length > 0}
          onOk={handleCancel}
          
          onCancel={handleCancel}
          footer={
            <Button type='primary' onClick={handleCancel}>
              OK
            </Button>
          }
          maskClosable={true}
          width={1000}>
          <Table columns={columns} dataSource={Employee} pagination={true} />
        </Modal>
      </Form>
    </>
  );
};

export default BulkUpload;
