import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Modal, Button, Col, Row } from 'antd';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import DashboardHeader from './DashboardHeader';

const Chart = (props) => {
  const { chartData, userConfig, locationList, contractorList } = props;
  const [chart, setChart] = useState(null);
  const [filters, setFilters] = React.useState({
    contractors: [],
    locations: [],
    dateRange: [],
  });

  const today = dayjs();

  const filtersOnLoad = () => {
    let query = {};

    const dateRange = [today.subtract(1, 'month').startOf('day'), today];
    query['jobDetails.doj'] = {
      $gte: new Date(dateRange[0].format('YYYY-MM-DD')).toISOString(),
      $lte: new Date(dateRange[1].format('YYYY-MM-DD')).toISOString(),
    };
    if (['admin', 'approver'].includes(userConfig.role)) {
      query.locationId = { $in: locationList };
      query['jobDetails.contractorName'] = { $in: contractorList };
    } else if (['ir', 'supervisor'].includes(userConfig.role)) {
      // query.createdBy = [userConfig.userid]
    }

    setFilters({
      dateRange: dateRange,
      contractors: contractorList,
      locations: locationList,
    });

    console.log('onLoad query', query);
    return query;
  };

  const createChart = () => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: chartData.baseUrl,
    });

    const query = filtersOnLoad();

    const newChart = sdk.createChart({
      chartId: chartData.chartId,
      filter: query,
    });

    newChart.render(document.getElementById(chartData.chartId)).catch((e) => {
      window.alert('Chart failed to initialise');
      console.log('58', e);
    });

    setChart(newChart);
  };

  useEffect(() => {
    createChart();
  }, []);

  const handleEditClick = () => {
    window.open(chartData?.editLink, '_blank');
  };

  const downloadPDF = () => {
    const publicViewLink = `${chartData.baseUrl}/embed/charts?id=${chartData.chartId}&autorefresh=true&theme=light`;
    window.open(publicViewLink, '_blank');
  };

  const downloadCSV = async () => {
    const data = await chart.getData();
    const { documents, fields } = data;

    const headers = `${fields.group},${fields.value}\n`;
    const rows = documents.map((doc) => `${doc.group},${doc.value}`).join('\n');
    const csvContent = headers + rows;

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = 'chart_data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <DashboardHeader
        filters={filters}
        setFilters={setFilters}
        downloadCSV={downloadCSV}
        downloadPDF={downloadPDF}
        userConfig={userConfig}
        chart={chart}
        locationList={locationList}
        contractorList={contractorList}
      />
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          {/* <Button
              disabled={!permissions?.edit}
              type='primary'
              onClick={handleEditClick}>
              Edit
            </Button> */}
        </Col>
      </Row>
      <Row>
        <div
          id={chartData.chartId}
          role='region'
          aria-label='Chart container'
          style={{
            background: '#FFFFFF',
            border: 'none',
            borderRadius: '2px',
            boxShadow: '0 2px 10px 0 rgba(70, 76, 79, 0.2)',
            width: '378px',
            height: '439px',
          }}></div>
      </Row>
    </>
  );
};

export default Chart;
