import React, { useEffect } from 'react';
import { Form, Input, Modal, Select, notification } from 'antd';
import { orgEmailValidator } from '../../validators';
import useAuth from '../../hooks/useAuth';
import { debounce } from 'lodash';

import axios from '../../services/axiosInstance';
import { psnNumber, getDetails, getPermissionList } from '../../services/auth';

const { Option } = Select;

export default function DesignationFormModal({
  open,
  onOk,
  onCancel,
  initialValues = {},
  checkIsValidAddition,
  isEdit = false,
  locationsData,
}) {
  const { userConfig } = useAuth();

  const formRef = React.useRef();
  const [users, setUsers] = React.useState({});
  const [dataFetched, setDataFetched] = React.useState(false);
  const [permissions, setPermissions] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [filteredLocation, setFilteredLocation] = React.useState(locationsData);
  // const handleRoleChange = (index, selectedRole) => {
  //   const levels = [
  //     { value: 1, label: 'IR-Contract' },
  //     { value: 2, label: 'Medical Officer' },
  //     { value: 3, label: 'Safety Officer' },
  //   ];

  //   const shopOptions = [
  //     { value: 'head', label: 'Head' },
  //     { value: 'incharge', label: 'Incharge' },
  //     { value: 'coordinator', label: 'Coordinator' },
  //     { value: 'supervisor', label: 'Supervisor' },
  //   ];

  //   const IrLevels = [{ value: 'IR-Regular', label: 'IR-Regular' }];

  //   // Check the selected role and set the variable accordingly
  //   if (selectedRole === 'approver') {
  //     setSelectedOptions(levels);
  //   } else if (selectedRole === 'shop') {
  //     setSelectedOptions(shopOptions);
  //   } else if (selectedRole === 'ir') {
  //     setSelectedOptions(IrLevels);
  //   } else {
  //     // Handle other roles or set a default value if needed
  //     setSelectedOptions([]);
  //   }

  //   //

  //   // You can use the selectedOptions variable for further logic or set it to the component state
  // };
  const handleRoleChange = (selectedRole) => {
    // Define options based on selected role
    let levelOptions = [];
    if (selectedRole === 'approver') {
      levelOptions = [
        { value: 1, label: 'IR-Contract' },
        // { value: 2, label: 'Medical Officer' },
        // { value: 3, label: 'Safety Officer' },
        // Sachin's code
        { value: 2, label: 'Safety Officer' },
        { value: 3, label: 'Medical Officer' },
      ];
    } else if (selectedRole === 'shop') {
      levelOptions = [
        { value: 'head', label: 'Head' },
        { value: 'incharge', label: 'Incharge' },
        { value: 'coordinator', label: 'Coordinator' },
        { value: 'supervisor', label: 'Supervisor' },
      ];
    } else if (selectedRole === 'ir') {
      levelOptions = [{ value: 'IR-Regular', label: 'IR-Regular' }];
    }

    // Set the options for level
    form.setFieldsValue({ level: undefined }); // Reset level value
    setSelectedOptions(levelOptions);
  };

  const orgId = userConfig.orgId;

  React.useEffect(() => {
    EmployeeList();
    formRef.current?.setFieldsValue(initialValues);
  }, [open, initialValues]);

  const currentValues = formRef.current?.getFieldsValue(); // Get current form values
  const updatedValues = {
    ...currentValues,
    roleName: currentValues?.roleName?.selectedRoleType, // Set roleName to roleName.selectedRoleType value
  };
  formRef.current?.setFieldsValue(updatedValues);

  async function EmployeeList() {
    const Response = await axios.get('/supplier');
    const Response1 = Response.data.suppliers;
    setUsers({ Response1 });
  }

  const onSubmit = (value) => {
    formRef.current?.submit();
  };

  const onFinish = (value) => {
    const roleName = value.roleName?.selectedRoleType || initialValues.roleName;

    const selectedPermission = permissions.find((item) => {
      return item._id == value.roleName;
    });
    // value.roleName = selectedPermission?.permissions;
    //

    // if (checkIsValidAddition(value)) {
    //   onOk(value, isEdit);
    // }
    // Create an object with permissions and selectedRoleType
    value.roleName = {
      permissions: selectedPermission?.permissions,
      selectedRoleType: selectedPermission?.selectedRoleType,
    };

    //value.name = `${value.firstName} ${value.lastName}`;

    // if (checkIsValidAddition(value)) {
    //   onOk(value, isEdit);
    // }
    if (checkIsValidAddition(value)) {
      onOk(value, isEdit, selectedPermission?.selectedRoleType, roleName); // Pass the role name here
    }
  };

  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };

  React.useEffect(() => {
    if (!dataFetched) {
      fetchData();
    }
  }, [dataFetched]); // The empty dependency array ensures that the effect runs only once on mount

  useEffect(() => {
    if (
      userConfig?.role == 'admin' &&
      locationsData?.locations &&
      userConfig?.location
    ) {
      const newObj = {
        locations: locationsData?.locations?.filter(
          (l) => l.id == userConfig?.location
        ),
      };
      setFilteredLocation(newObj);
    } else {
      setFilteredLocation({ locations: locationsData.locations.slice(1) });
    }
  }, [locationsData]);

  const fetchData = () => {
    getDetails(orgId)
      .then((response) => {
        const filteredData = response?.data
          .map((item) => {
            // Check if the selectedRole is "Approver"
            if (
              item.selectedRole === 'Approver' ||
              item.selectedRole === 'Shop' ||
              item.selectedRole === 'IR'
            ) {
              // If it is "Approver," return the modified item or just the property you need
              return {
                createdAt: item.createdAt,
                orgId: item.orgId,
                permissions: item.permissions,
                selectedRole: item.selectedRole,
                selectedRoleType: item.selectedRoleType,
                _id: item._id,
                // Add other properties as needed
              };
            }

            // If selectedRole is not "Approver," return null or an empty object
            return null;
          })
          .filter((filteredItem) => filteredItem !== null); // Remove null entries from the result

        //

        setPermissions(filteredData);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  //
  const roles = [
    { value: 'approver', label: 'Approver' },
    { value: 'shop', label: 'Shop' },
    { value: 'ir', label: 'IR' },
  ];
  const [form] = Form.useForm();

  // const handleInputChange = async (value) => {
  //   psnNumber(value)
  //     .then((res) => {
  //
  //       // setEmpData('Viresh');
  //       form.setFieldValue(
  //         'firstName',
  //         res?.data?.result?.emp?.personalDetails?.firstName
  //       );
  //       form.setFieldValue(
  //         'lastName',
  //         res?.data?.result?.emp?.personalDetails?.lastName
  //       );

  //       notification.success({ message: res.data.message });
  //     })
  //     .catch((err) => {
  //       notification.error({ message: err.response.data.message });
  //     });
  // };

  const debouncedHandleInputChange = debounce(async (value) => {
    psnNumber(value)
      .then((res) => {
        // setEmpData('Viresh');
        form.setFieldValue(
          'firstName',
          res?.data?.result?.emp?.personalDetails?.firstName
        );
        form.setFieldValue(
          'lastName',
          res?.data?.result?.emp?.personalDetails?.lastName
        );

        // notification.success({ message: res.data.message });
      })
      .catch((err) => {
        // notification.error({ message: err.response.data.message });
      });
  }, 1000); // Adjust the debounce delay as needed (500 milliseconds in this example)

  return (
    <Modal
      title={isEdit ? 'Edit' : 'Add Designation'}
      open={open}
      onOk={onSubmit}
      maskClosable={false} // Prevent closing on outside click
      onCancel={_onCancel}
      okText={isEdit ? 'Save' : 'Add '}
      autoComplete='off'>
      <Form
        name='Designation'
        ref={formRef}
        form={form}
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}>
        <Form.Item
          label='PS Number'
          name='psNumber'
          // rules={[{ required: true, message: 'Enter PS Number' }]}
        >
          <Input onChange={(e) => debouncedHandleInputChange(e.target.value)} />
        </Form.Item>
        <Form.Item
          label='First Name'
          name='firstName'
          rules={[{ required: true, message: 'Enter First Name' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label='Last Name'
          name='lastName'
          rules={[{ required: true, message: 'Enter Last Name' }]}>
          <Input />
        </Form.Item>

        {/* <Form.Item label='Role' name='role' hidden={true}>
          <Select
            disabled
            onChange={(value) => {
              // form.setFieldsValue({
              //   [i]: { level: undefined, roleName: undefined },
              // });
              handleRoleChange(value);
            }}>
            <Option value='approver'>Approver</Option>
          </Select>
        </Form.Item> */}

        <Form.Item
          label='Role'
          name='role'
          rules={[{ required: true, message: 'Enter Role Name' }]}>
          <Select
            onChange={(value) => {
              form.setFieldsValue({ level: undefined }); // Reset level value
              handleRoleChange(value);
            }}>
            {roles?.map((role) => (
              <Option key={role.value} value={role.value}>
                {role.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Level'
          name='level'
          rules={[{ required: true, message: 'Select Level' }]}>
          <Select>
            {selectedOptions?.map((level) => (
              <Option key={level.value} value={level.value}>
                {level.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Role Name'
          name='roleName'
          initialValue={initialValues?.roleName}
          rules={[{ required: true, message: 'Enter Role Name' }]}>
          <Select>
            {permissions?.map((level, index) => (
              <Option key={index} value={level?._id}>
                {level.selectedRoleType}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* <Form.Item
          label='Reviewers Role '
          name='reviewersRole'
          // initialValue={initialValues?.roleName}
          // rules={[{ required: true, message: 'Enter Reviewers Role' }]}
        >
          <Select>
            <Option value='shopSupervisor'>Shop Supervisor</Option>
            <Option value='shopCoordinator'>Shop Coordinator</Option>
            <Option value='shopInCharge'>Shop In-charge</Option>
            <Option value='shopHead'>Shop Head</Option>
            <Option value='pcHead'>PC Head</Option>
            <Option value='buHead'>BU Head</Option>
            <Option value='locationHead'>Location Head</Option>
            <Option value='indepentCompanyHead'>
              Independent Company (IC) Head
            </Option>

            <Option value='planner'>Planner</Option>
            <Option value='planningLead'>Planning Lead</Option>
            <Option value='planningHead'>Planning Head</Option>
            <Option value='deviceAdmin'>Device Admin</Option>
            <Option value='integrationLead'>Integration Lead</Option>
            <Option value='analyticsBIAnalyst'>Analytics - BI Analyst</Option>
            <Option value='payrollAdmin'>Payroll Admin (IR Admin)</Option>
            <Option value='itAdmin'>IT Admin</Option>
            <Option value='centralIr'>Central IR</Option>
            <Option value='centralSubContractingCell'>
              Central Sub Contracting Cell
            </Option>
            <Option value='ukgAdminstartor'>
              UKG Administrator (Superuser)
            </Option>
          </Select>
        </Form.Item> */}

        <Form.Item
          label='Location'
          name='locationId'
          rules={[{ required: true, message: 'Enter User ID' }]}>
          <Select disabled={isEdit ? true : false}>
            {filteredLocation?.locations?.map((option, index) => (
              <Option key={index} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          label='Unit Id'
          name='unitId'
          rules={[{ required: true, message: 'Enter Unit Id' }]}>
          <Input disabled={isEdit ? true : false} />
        </Form.Item> */}

        <Form.Item
          name='userid'
          label='User ID'
          rules={[
            { required: true, message: 'Enter User ID' },
            {
              pattern: /^[A-Za-z0-9]+$/,
              message: 'No Special Characters Allowed',
            },
          ]}
          getValueFromEvent={(e) => e.target.value.toUpperCase()}>
          <Input autoComplete='off' />
        </Form.Item>
        <Form.Item
          label='Password'
          name='password'
          rules={[
            { required: true, message: 'Enter Password' },
            {
              pattern: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$',
              message:
                'Must contain minimum eight characters, atleast one number and one special character',
            },
          ]}>
          <Input.Password maxLength={20} autoComplete='new-password' />
        </Form.Item>
        <Form.Item
          label='Email'
          name='emailId'
          rules={[
            { required: true, message: 'Enter Email' },
            { type: 'email', message: 'Enter valid Email' },
            // {
            //   validator: (_, value) => {
            //     const emailRegex =
            //       /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            //     if (emailRegex.test(value)) {
            //       return orgEmailValidator(value, userConfig.orgId);
            //     }
            //   },
            // },
          ]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
