import React, { useEffect, useRef, useState } from 'react';
import { Switch, Table, Typography, Input, Space, Col, Button } from 'antd';
import { EyeFilled, SearchOutlined } from '@ant-design/icons';
import axios from '../services/axiosInstance';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import LicenceManagementModal from './licenceManagementModal';
import { callCreateLicence } from '../services/auth';

const LicenceManagement = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const searchInput = useRef(null);

  useEffect(() => {
    getOrgList();
  }, []);

  const getOrgList = async () => {
    try {
      const response = await axios.get('/org?pageSize=100&pageNo=1');
      const res = response.data.orgList;

      setUserData(
        res.map((row) => ({
          key: row.id,
          id: row.id,
          _id: row._id,
          name: row.name,
          productLicenseKey: row.productLicenseKey,
          pf: row.pf,
          esi: row.esi,
        }))
      );
      setLoading(false);
    } catch (error) {
      console.error('Error fetching org list:', error);
    }
  };

  const updateLicence = (features, expiry) => {
    const data = {
      orgId: selectedItemId?.key,
      features: features,
      expiry: expiry,
      status: 'active',
    };

    callCreateLicence(data)
      .then((res) => {
        setSelectedItemId('');
        getOrgList();
        setIsModalVisible(false);
      })
      .catch((error) => {});
  };
  const showModal = (id) => {
    setSelectedItemId(id);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),

      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.id.startsWith(value),
      // width: '30%',
      // sorter: (a, b) => a.id.length - b.id.length,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),

      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.name.startsWith(value),
      // width: "30%",
      // sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      // width: 150,
      fixed: 'left', // Freeze this column
    },
    {
      title: 'Product Licence Key',
      dataIndex: 'productLicenseKey',
      key: 'productLicenseKey',
      render: (item) => <span>{item ? item.substring(0, 30) : ''}....</span>,
    },
    {
      title: 'Action',
      // dataIndex: '_id',
      render: (record) => (
        <Typography>
          <Link onClick={() => showModal(record)}>
            <EyeFilled />
          </Link>
        </Typography>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  return (
    <>
      <h2>Principal Employer Organisation List</h2>
      <Table
        columns={columns}
        dataSource={userData}
        onChange={onChange}
        pagination={true}
      />

      {/* Modal for displaying details */}
      <LicenceManagementModal
        // title='Item Details'
        open={isModalVisible}
        onOk={updateLicence}
        onCancel={handleCancel}
        token={selectedItemId}
        footer={null}></LicenceManagementModal>
    </>
  );
};

export default LicenceManagement;
