import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { Table, Space, Typography } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import { getAllReports, getDetails } from '../../../services/auth';
import ChartModal from './ChartModal';
import { getSupplier } from '../../../services/supplier';

const Reports = () => {
  const { user, userConfig } = useAuth();

  const [reportsData, setReportsData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [permissions, setPermissions] = useState({});
  const [contractorList, setContractorList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  function handleView(record) {
    setSelectedReport(record);
    setModalVisible(true);
  }

  useEffect(() => {
    getReportsData();
    fetchData();
  }, []);

  const getReportsData = async () => {
    const response = await getAllReports();
    setReportsData(
      response?.data?.data?.filter((chart) => chart.type == 'REPORTS')
    );
    getPermissions(userConfig.orgId);
  };

  const fetchData = async () => {
    try {
      const res = await getSupplier(userConfig.orgId);
      const list = [];
      const locationId = userConfig.location || userConfig.locationId;

      if (locationId == null) {
        // case of super admin
        const locationMap = {};
        res.data?.suppliers?.map((c) => {
          list.push(c.name);
          locationMap[c.location] = 1;
        });
        setLocationList(Object.keys(locationMap));
      } else if (locationId) {
        res.data?.suppliers?.map((c) => {
          if (c.location == locationId) {
            list.push(c.name);
          }
        });
        setLocationList([locationId]);
      }
      setContractorList(list);
    } catch (error) {
      console.error('Error fetching contractor name:', error);
    }
  };

  const getPermissions = (orgId) => {
    getDetails(orgId)
      .then((response) => {
        const filteredData = response?.data?.filter(
          (i) => i.selectedRole?.toLowerCase() == userConfig.role.toLowerCase()
        );
        if (filteredData.length > 0) {
          setPermissions(filteredData[0]?.permissions?.Reports_And_Dashboard);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const columns = [
    {
      title: 'SI. No.',
      dataIndex: 'index',
      width: 100,
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Name Of Reports',
      dataIndex: 'reportName',
      key: 'name',
    },
    {
      title: 'Actions',
      dataIndex: 'reportName',
      render: (key) => (
        <Space>
          <Typography
            onClick={() =>
              handleView(reportsData?.find((item) => item.reportName === key))
            }>
            <EyeFilled />
          </Typography>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table dataSource={reportsData} columns={columns} />
      {modalVisible && (
        <ChartModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          selectedReport={selectedReport}
          permissions={permissions}
          userConfig={userConfig}
          contractorList={contractorList}
          locationList={locationList}
        />
      )}
    </div>
  );
};

export default Reports;
