import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { EmployeeMasterColumns, beforeUpload } from './Constant';

import {
  Form,
  Row,
  Modal,
  Col,
  Button,
  Divider,
  Table,
  Typography,
  Tooltip,
  Upload,
} from 'antd';

const EmployeeMaster = () => {
  const { Title } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Handle the OK button click if needed
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    // Handle the Cancel button click if needed
    setIsModalVisible(false);
  };

  const customRequest = ({ file, onSuccess }) => {
    onSuccess(file);
  };

  return (
    <>
      <Title level={4} style={{ textAlign: 'center' }}>
        Employee Master
      </Title>
      <Form layout='vertical'>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title='Import '>
            <Upload
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}>
              <Button
                // type='primary'
                className='mr2 HeaderButtonColor'
                style={{ marginRight: '10px' }}>
                Import{' '}
              </Button>
            </Upload>
          </Tooltip>

          <Tooltip title='Export'>
            <Button
              className='mr2 HeaderButtonColor'
              style={{ marginRight: '10px' }}
              // type='primary'
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}>
              Export{' '}
            </Button>{' '}
          </Tooltip>
          <Tooltip title='Add New'>
            <Button
              className='mr2 HeaderButtonColor'
              type='primary'
              onClick={showModal}>
              Add New
            </Button>
          </Tooltip>
          <Modal
            title='Add New Item'
            visible={isModalVisible}
            onOk={handleOk}
            maskClosable={false} // Prevent closing on outside click
            onCancel={handleCancel}>
            {/* Add your form or content for the modal here */}
            <p>Modal content goes here.</p>
          </Modal>
        </div>

        <Divider></Divider>
        <Table
          className='TableDesign'
          bordered
          columns={EmployeeMasterColumns}
          //   dataSource={wageHistoryData}
        />
      </Form>
    </>
  );
};

export default EmployeeMaster;
