import React, { useState } from 'react';
import {
  Button,
  Upload,
  notification,
  Row,
  Col,
  Form,
  Typography,
  Select,
  Modal,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { bulkUploadContractWorkmenDocumentApi, bulkUploadAgencyDocumentApi } from '../services/auth';

const { Title } = Typography;
const { Option } = Select;

const FileUploader = () => {
  const [workmenFileDirectory, setWorkmenFileDirectory] = useState([]);
  const [agencyFileDirectory, setAgencyFileDirectory] = useState([]);
  const [uploadWorkmenDocumentResponse, setUploadWorkmenDocumentResponse] = useState([]);
  const [uploadAgencyDocumentResponse, setUploadAgencyDocumentResponse] = useState([]);
  const { userConfig } = useAuth();
  const [uploadWorkmenLogsView, setUploadWorkmenLogsView] = useState(false);
  const [uploadAgencyLogsView, setUploadAgencyLogsView] = useState(false);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
  });

  const handleChange = async (info) => {
    const files = await Promise.all(
      info.fileList.map(async (file) => {
        const base64Content = await getBase64(file.originFileObj); // Get Base64 content
        return {
          blob: file.originFileObj, // Original file object
          url: `${process.env.REACT_APP_GCP_PATH}/${userConfig?.orgId}/contract_workmen/${file.name}`, // Constructed GCP path
          mimeType: file.type, // File MIME type
          base64Content, // Add Base64 content
        };
      })
    );
    // notification.success({message: `${info.fileList.length} files uploaded. Please refresh if you have to re-upload`})
    setWorkmenFileDirectory(files);
  };

  const handleAgencyChange = async (info) => {
    const files = await Promise.all(
      info.fileList.map(async (file) => {
        const base64Content = await getBase64(file.originFileObj); // Get Base64 content
        return {
          blob: file.originFileObj, // Original file object
          url: `${process.env.REACT_APP_GCP_PATH}/${userConfig?.orgId}/contract_agency/${file.name}`, // Constructed GCP path
          mimeType: file.type, // File MIME type
          base64Content, // Add Base64 content
        };
      })
    );
    // notification.success({message: `${info.fileList.length} files uploaded. Please refresh if you have to re-upload`})
    setAgencyFileDirectory(files);
  };

  const uploadBulkDocuments = async (values) => {
    bulkUploadContractWorkmenDocumentApi({
      files: workmenFileDirectory,
      location: values.locationId})
      .then((response) => {
        notification.success({message: `${workmenFileDirectory.length} files processed`})
        setUploadWorkmenDocumentResponse(response?.data);
        setUploadWorkmenLogsView(true);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const agencyUploadBulkDocuments = async (values) => {
    bulkUploadAgencyDocumentApi({
      files: agencyFileDirectory,
      location: values.locationId})
      .then((response) => {
        notification.success({message: `${agencyFileDirectory.length} files processed`})
        setUploadAgencyDocumentResponse(response?.data);
        setUploadAgencyLogsView(true);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleWorkmanModalClose = () => {
    setUploadWorkmenLogsView(false);
    setUploadWorkmenDocumentResponse([]);
  }

  const handleAgencyModalClose = () => {
    setUploadAgencyLogsView(false);
    setUploadWorkmenDocumentResponse([]);
  }

  return (
    <>
      <Row>
        <Title level={5} style={{ fontWeight: 'bolder' }}>
          Workmen Bulk Document Upload
        </Title>
      </Row>
      <Form layout='vertical' onFinish={uploadBulkDocuments}>
        <Row>
          <Col span={6}>
            <Form.Item
              name='locationId'
              label='Location Id'
              rules={[{ required: true, message: 'Enter LocationID' }]}>
              <Select style={{ width: '60%' }}>
                <Option value={userConfig?.locationId}>{userConfig?.locationId}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='Upload Bulk Documents'
              name='bulkDocuments'
              rules={[{ required: true, message: 'Select folder to upload' }]}
              >
              <Upload
                multiple
                directory
                showUploadList={false} // Hides default Ant Design file list
                beforeUpload={() => false}
                onChange={handleChange} // Custom replacement handler
              >
                <Button>Upload Folder</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='upload' label='Upload Bulk Data'>
              <Button
                type='primary'
                disabled={workmenFileDirectory.length === 0}
                htmlType='submit'>
                Upload Documents
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row>
        <Title level={5} style={{ fontWeight: 'bolder' }}>
          Agency Bulk Document Upload
        </Title>
      </Row>
      <Form layout='vertical' onFinish={agencyUploadBulkDocuments}>
        <Row>
          <Col span={6}>
            <Form.Item
              name='locationId'
              label='Location Id'
              rules={[{ required: true, message: 'Enter LocationID' }]}>
              <Select style={{ width: '60%' }}>
                <Option value={userConfig?.locationId}>{userConfig?.locationId}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='Upload Bulk Documents'
              name='bulkDocuments'
              rules={[{ required: true, message: 'Select folder to upload' }]}
              >
              <Upload
                multiple
                directory
                showUploadList={false} // Hides default Ant Design file list
                beforeUpload={() => false}
                onChange={handleAgencyChange} // Custom replacement handler
              >
                <Button>Upload Folder</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='upload' label='Upload Bulk Data'>
              <Button
                type='primary'
                disabled={agencyFileDirectory.length === 0}
                htmlType='submit'>
                Upload Documents
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        title='Uploads Logs'
        open={uploadWorkmenLogsView}
        onOk={handleWorkmanModalClose}
        
        onCancel={handleWorkmanModalClose}
        footer={
          <Button type='primary' onClick={handleWorkmanModalClose}>
            OK
          </Button>
        }
        maskClosable={true}
        width={500}>
        <>
          <p><b>Records found: </b>{uploadWorkmenDocumentResponse?.records_found?.length ? uploadWorkmenDocumentResponse?.records_found?.join(", ") : "None"}</p>
          <p><b>Records not found: </b>{uploadWorkmenDocumentResponse?.records_not_found?.length ? uploadWorkmenDocumentResponse?.records_not_found?.join(", ") : "None"}</p>
        </>
      </Modal>
      <Modal
        title='Uploads Logs'
        open={uploadAgencyLogsView}
        onOk={handleAgencyModalClose}
        onCancel={handleAgencyModalClose}
        footer={
          <Button type='primary' onClick={handleAgencyModalClose}>
            OK
          </Button>
        }
        maskClosable={true}
        width={500}>
          <>
            <p><b>Records found: </b>{uploadAgencyDocumentResponse?.records_found?.length ? uploadAgencyDocumentResponse?.records_found?.join(", ") : "None"}</p>
            <p><b>Records not found: </b>{uploadAgencyDocumentResponse?.records_not_found?.length ? uploadAgencyDocumentResponse?.records_not_found?.join(", ") : "None"}</p>
          </>
      </Modal>
    </>
  );
};

export default FileUploader;
