import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Table,
  Divider,
  Typography,
  DatePicker,
  Select,
} from 'antd';
import { EyeFilled } from '@ant-design/icons';
import axios from '../services/axiosInstance';
import moment from 'moment/moment';
import { Modal, Steps } from 'antd';

import ReactEcharts from 'echarts-for-react';
import useAuth from '../hooks/useAuth';
import { getContractorOnboard } from '../services/auth';
import { setLoader } from '../actions';
const { Title } = Typography;
const { MonthPicker } = DatePicker;
const { Option } = Select;
const { Step } = Steps;

const data = [
  { supplier: 'Supplier A', employees: 50, mismatches: 10 },
  { supplier: 'Supplier B', employees: 75, mismatches: 15 },
  { supplier: 'Supplier C', employees: 100, mismatches: 25 },
];

const Dashboard = () => {
  const { userConfig } = useAuth();
  const [selectedDropdown1, setSelectedDropdown1] = useState('PF');
  const [reportData, setReportData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [exceptionRecords, setExceptionRecords] = useState(false);
  const [excepEmployeeRecords, setExcepEmployeeRecords] = useState([]);
  const [month, setMonth] = useState(moment().month() + 1);
  const [year, setYear] = useState(moment().year());
  const [visible, setVisible] = useState(false);
  const [users, setUsers] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [Employee, setEmployee] = useState([]);
  const [Employee1, setEmployee1] = useState([]);
  const [modalColumns1, setModalColumns1] = useState([]);
  const [modalColumns2, setModalColumns2] = useState([]);

  const [modalColumns3, setModalColumns3] = useState([]);

  const [principalEmployername, setprincipalEmployername] = useState(null);
  const [noMatchRecords, setNoMatchRecords] = useState([]);

  const [transactionData, setTransactionData] = useState([]);
  function handleMonthChange(value) {
    if (value) {
      setMonth(value.month() + 1);
      setYear(value.year());
    }
  }
  React.useEffect(() => {
    EmployeeList();
    EmployeeData();
    OrganizationInfo();
  }, []);

  React.useEffect(() => {
    EmployeeData();
  }, [selectedDropdown1]);

  function handleDropdown1Change(value) {
    setSelectedDropdown1(value);
  }
  const EmployeeData = () => {
    setLoader(true);
    const Response = axios
      .get(
        `report/dashboard/data?orgId=${userConfig.orgId}&month=${month}&year=${year}&type=${selectedDropdown1}`
      )
      .then((response) => {
        setLoader(false);

        setTransactionData(response.data.data);
        setEmployee1(
          response.data.data.map((row) => ({
            ContractorId: row.supplierId,
            ContractorName: row.supplierName,
            NoofEmp: row.allCount,
            Noofmis: row.exceptionCount,
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoader(false);

        console.error(error);
        setLoading(false);
      });
  };
  function handleView(id) {
    setVisible(true);
    setLoading(true);
    if (selectedDropdown1 === 'PF') {
      setLoader(true);

      axios
        .get(
          `report/transactions/latest?orgId=${userConfig.orgId}&month=${month}&year=${year}&type=${selectedDropdown1}&supplierId=${id}`
        )
        .then((response) => {
          setLoader(false);
          setReportData(response.data);
          const reportData = response.data.data.report || [];

          setEmployee(
            reportData?.map((row) => ({
              empName: row['Emp Name'],
              empCode: row['Emp Code'],
              Uannumber: row['UAN Number'],
              Ebwr: row['Emp Basic Pay per Wage Reg'],
              Ebec: row['Emp Basic Pay per ECR Challan'],
              Basicwagesdiff: row['Basic Wages Diff'],
              Eepfwr: row['EE PF per Wage Reg'],
              Eepfc: row['EE PF per ECR Challan'],
              EmployeeDiff: row['Employee PF Diff'],
              Epswr: row['EPS per Wage Reg'],
              Epsec: row['EPS per ECR Challan'],
              Epsdiff: row['EPS Diff'],
              Erpfwr: row['ER PF per Wage Reg'],
              Erpfwrc: row['ER PF per ECR Challan'],
              EmployerDiff: row['Employer PF Diff'],
            }))
          );
          setModalColumns1([
            {
              title: 'Workmen Name',
              dataIndex: 'empName',
              key: 'empName',
            },
            {
              title: 'Workmen Code',
              dataIndex: 'empCode',
              key: 'empCode',
            },
            {
              title: 'UAN Number',
              dataIndex: 'Uannumber',
              key: 'Uannumber',
            },
            {
              title: 'Emp Basic Pay Per Wage Reg',
              dataIndex: 'Ebwr',
              key: 'Ebwr',
            },
            {
              title: 'Emp Basic Pay Per ECR Challan',
              dataIndex: 'Ebec',
              key: 'Ebec',
            },
            {
              title: 'Basic Wages Diff',
              dataIndex: 'Basicwagesdiff',
              key: 'Basicwagesdiff',
            },
            {
              title: 'EE PF per Wage Reg',
              dataIndex: 'Eepfwr',
              key: 'Eepfwr',
            },
            {
              title: 'EE PF per ECR Challan',
              dataIndex: 'Eepfc',
              key: 'Eepfc',
            },
            {
              title: 'Employee PF Diff',
              dataIndex: 'EmployeeDiff',
              key: 'EmployeeDiff',
            },
            {
              title: 'EPS per Wage Reg',
              dataIndex: 'Epswr',
              key: 'Epswr',
            },
            {
              title: 'EPS per ECR Challan',
              dataIndex: 'Epsec',
              key: 'Epsec',
            },
            {
              title: 'EPS Diff',
              dataIndex: 'Epsdiff',
              key: 'Epsdiff',
            },
            {
              title: 'ER PF per Wage Reg',
              dataIndex: 'Erpfwr',
              key: 'Erpfwr',
            },
            {
              title: 'ER PF per ECR Challan',
              dataIndex: 'Erpfwrc',
              key: 'Erpfwrc',
            },
            {
              title: 'Employer PF Diff',
              dataIndex: 'EmployerDiff',
              key: 'EmployerDiff',
            },
          ]);

          const data = response.data;
          setExceptionRecords(data.data.exceptionCount > 0);
          setExcepEmployeeRecords(
            data.data.report
              ?.filter((record) => record.isExceptionRecord)
              .map((record) => ({
                empName1: record['Emp Name'],
                empCode1: record['Emp Code'],
                Uannumber1: record['UAN Number'],
                Ebwr1: record['Emp Basic Pay per Wage Reg'],
                Ebec1: record['Emp Basic Pay per ECR Challan'],
                Basicwagesdiff1: record['Basic Wages Diff'],
                Eepfwr1: record['EE PF per Wage Reg'],
                Eepfc1: record['EE PF per ECR Challan'],
                EmployeeDiff1: record['Employee PF Diff'],
                Epswr1: record['EPS per Wage Reg'],
                Epsec1: record['EPS per ECR Challan'],
                Epsdiff1: record['EPS Diff'],
                Erpfwr1: record['ER PF per Wage Reg'],
                Erpfwrc1: record['ER PF per ECR Challan'],
                EmployerDiff1: record['Employer PF Diff'],
              }))
          );
          setNoMatchRecords(
            data.data.report
              ?.filter((record) => record.isNoMatch)
              .map((record) => ({
                empName3: record['Emp Name'],
                empCode3: record['Emp Code'],
                Uannumber3: record['UAN Number'],
                // Ebwr1: record["Emp Basic Pay per Wage Reg"],
                // Ebec1: record["Emp Basic Pay per ECR Challan"],
                // Basicwagesdiff1: record["Basic Wages Diff"],
                // Eepfwr1: record["EE PF per Wage Reg"],
                // Eepfc1: record["EE PF per ECR Challan"],
                // EmployeeDiff1: record["Employee PF Diff"],
                // Epswr1: record["EPS per Wage Reg"],
                // Epsec1: record["EPS per ECR Challan"],
                // Epsdiff1: record["EPS Diff"],
                // Erpfwr1: record["ER PF per Wage Reg"],
                // Erpfwrc1: record["ER PF per ECR Challan"],
                // EmployerDiff1: record["Employer PF Diff"],
              }))
          );
          setModalColumns2([
            {
              title: 'Workmen Name',
              dataIndex: 'empName1',
              key: 'empName1',
            },
            {
              title: 'Workmen Code',
              dataIndex: 'empCode1',
              key: 'empCode1',
            },
            {
              title: 'UAN Number',
              dataIndex: 'Uannumber1',
              key: 'Uannumber1',
            },
            {
              title: 'Emp Basic Pay Per Wage Reg',
              dataIndex: 'Ebwr1',
              key: 'Ebwr1',
            },
            {
              title: 'Emp Basic Pay Per ECR Challan',
              dataIndex: 'Ebec1',
              key: 'Ebec1',
            },
            {
              title: 'Basic Wages Diff',
              dataIndex: 'Basicwagesdiff1',
              key: 'Basicwagesdiff1',
            },
            {
              title: 'EE PF per Wage Reg',
              dataIndex: 'Eepfwr1',
              key: 'Eepfwr1',
            },
            {
              title: 'EE PF per ECR Challan',
              dataIndex: 'Eepfc1',
              key: 'Eepfc1',
            },
            {
              title: 'Employee PF Diff',
              dataIndex: 'EmployeeDiff1',
              key: 'EmployeeDiff1',
            },
            {
              title: 'EPS per Wage Reg',
              dataIndex: 'Epswr1',
              key: 'Epswr1',
            },
            {
              title: 'EPS per ECR Challan',
              dataIndex: 'Epsec1',
              key: 'Epsec1',
            },
            {
              title: 'EPS Diff',
              dataIndex: 'Epsdiff1',
              key: 'Epsdiff1',
            },
            {
              title: 'ER PF per Wage Reg',
              dataIndex: 'Erpfwr1',
              key: 'Erpfwr1',
            },
            {
              title: 'ER PF per ECR Challan',
              dataIndex: 'Erpfwrc1',
              key: 'Erpfwrc1',
            },
            {
              title: 'Employer PF Diff',
              dataIndex: 'EmployerDiff1',
              key: 'EmployerDiff1',
            },
          ]);

          setModalColumns3([
            {
              title: 'Workmen Name',
              dataIndex: 'empName3',
              key: 'empName3',
            },
            {
              title: 'Workmen Code',
              dataIndex: 'empCode3',
              key: 'empCode3',
            },
            {
              title: 'UAN Number',
              dataIndex: 'Uannumber3',
              key: 'Uannumber3',
            },
          ]);

          // setLoading(false);
        })
        .catch((error) => {
          setLoader(false);

          console.error(error);
          // setLoading(false);
        });
    } else if (selectedDropdown1 === 'ESI') {
      setLoader(true);

      axios
        .get(
          `report/transactions/latest?orgId=${userConfig.orgId}&month=${month}&year=${year}&type=${selectedDropdown1}&supplierId=${id}`
        )
        .then((response) => {
          setLoader(false);
          setReportData(response.data);
          setEmployee(
            response.data.data.report?.map((row) => ({
              empName: row['Emp Name'],
              empCode: row['Emp Code'],
              esinumber: row['ESI No'],
              Egwr: row['Emp Gross Pay as per Wage Reg'],
              Egec: row['Emp Gross Pay as per MC Challan'],
              Diffamount: row['Diff amt in Wages'],
              Esiwr: row['ESI per Wage Reg'],
              Esic: row['ESI per MC Challan'],
              Diffamountcontribution: row['Diff Amount in Contribution'],
            }))
          );

          setModalColumns1([
            {
              title: 'Workmen Name',
              dataIndex: 'empName',
              key: 'empName',
            },
            {
              title: 'Workmen Code',
              dataIndex: 'empCode',
              key: 'empCode',
            },
            {
              title: 'ESI Number',
              dataIndex: 'esinumber',
              key: 'esinumber',
            },
            {
              title: 'Emp Gross Pay as per Wage Reg',
              dataIndex: 'Egwr',
              key: 'Egwr',
            },
            {
              title: "Emp Gross Pay as per MC Challan'",
              dataIndex: 'Egec',
              key: 'Egec',
            },
            {
              title: 'Diff amt in Wages',
              dataIndex: 'Diffamount',
              key: 'Diffamount',
            },
            {
              title: 'ESI per Wage Reg',
              dataIndex: 'Esiwr',
              key: 'Esiwr',
            },
            {
              title: 'ESI per MC Challan',
              dataIndex: 'Esic',
              key: 'Esic',
            },
            {
              title: 'Diff Amount in Contribution',
              dataIndex: 'Diffamountcontribution',
              key: 'Diffamountcontribution',
            },
          ]);
          const data = response.data;
          setExceptionRecords(data.data.exceptionCount > 0);
          setExcepEmployeeRecords(
            data.data.report
              ?.filter((record) => record.isExceptionRecord)
              .map((row) => ({
                empName1: row['Emp Name'],
                empCode1: row['Emp Code'],
                esiumber1: row['ESI No'],
                Egwr1: row['Emp Gross Pay as per Wage Reg'],
                Egec1: row['Emp Gross Pay as per MC Challan'],
                Diffamount1: row['Diff amt in Wages'],
                Esiwr1: row['ESI per Wage Reg'],
                Esic1: row['ESI per MC Challan'],
                Diffamountcontribution1: row['Diff Amount in Contribution'],
              }))
          );
          setModalColumns2([
            {
              title: 'Workmen Name',
              dataIndex: 'empName1',
              key: 'empName1',
            },
            {
              title: 'Workmen Code',
              dataIndex: 'empCode',
              key: 'empCode1',
            },
            {
              title: 'ESI Number',
              dataIndex: 'esiumber1',
              key: 'esiumber1',
            },
            {
              title: 'Emp Gross Pay as per Wage Reg',
              dataIndex: 'Egwr1',
              key: 'Egwr1',
            },
            {
              title: "Emp Gross Pay as per MC Challan'",
              dataIndex: 'Egec1',
              key: 'Egec1',
            },
            {
              title: 'Diff amt in Wages',
              dataIndex: 'Diffamount1',
              key: 'Diffamount1',
            },
            {
              title: 'ESI per Wage Reg',
              dataIndex: 'Esiwr1',
              key: 'Esiwr1',
            },
            {
              title: 'ESI per MC Challan',
              dataIndex: 'Esic1',
              key: 'Esic1',
            },
            {
              title: 'Diff Amount in Contribution',
              dataIndex: 'Diffamountcontribution1',
              key: 'Diffamountcontribution1',
            },
          ]);
          setNoMatchRecords(
            data.data.report
              ?.filter((record) => record.isNoMatch)
              .map((row) => ({
                empName3: row['Emp Name'],
                empCode3: row['Emp Code'],
                esinumber3: row['ESI No'],
                // Egwr: row["Emp Gross Pay as per Wage Reg"],
                // Egec: row["Emp Gross Pay as per MC Challan"],
                // Diffamount: row["Diff amt in Wages"],
                // Esiwr: row["ESI per Wage Reg"],
                // Esic: row["ESI per MC Challan"],
                // Diffamountcontribution: row["Diff Amount in Contribution"],
              }))
          );
          setModalColumns3([
            {
              title: 'Workmen Name',
              dataIndex: 'empName3',
              key: 'empName3',
            },
            {
              title: 'Workmen Code',
              dataIndex: 'empCode3',
              key: 'empCode3',
            },
            {
              title: 'ESI Number',
              dataIndex: 'esinumber3',
              key: 'esinumber3',
            },
          ]);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }

  async function EmployeeList() {
    const Response = await axios.get('/supplier');
    const Response1 = Response.data.suppliers;
    setUsers({ Response1 });
  }

  function handleModalCancel() {
    setVisible(false);
    // setData([]);
  }
  const columns = [
    {
      title: 'Contractor Name',
      dataIndex: 'ContractorName',
      key: 'ContractorName',
    },
    {
      title: 'Transaction Id',
      dataIndex: 'ContractorId',
      key: 'ContractorId',
    },

    { title: 'No. Of Workmen', dataIndex: 'NoofEmp', key: 'NoofEmp' },
    { title: 'No. Of Mismatches', dataIndex: 'Noofmis', key: 'Noofmis' },

    {
      title: 'Actions',
      dataIndex: 'Contractorname',
      render: (_, record) => (
        <Typography onClick={() => handleView(record.ContractorId)}>
          <EyeFilled />
        </Typography>
      ),
    },
  ];

  // React.useEffect(() => {
  //   ExceptionReport();
  // }, []);

  // async function ExceptionReport(){
  // const Response=axios.get( `/report/dashboard/data?orgId=${userConfig.orgId}&month=${month}&year=${year}&type=${selectedDropdown1}`);

  // }
  const barData = {
    legend: ['Employees', 'Mismatches', 'No Match'],
    xAxis: transactionData?.map((d) => d.supplierName),
    series: [
      { name: 'Employees', data: transactionData?.map((d) => d.allCount) },
      {
        name: 'Mismatches',
        data: transactionData?.map((d) => d.exceptionCount),
      },
      {
        name: 'No Match',
        data: transactionData?.map((d) => d.noMatchCount),
      },
    ],
  };

  //-----------
  const OrganizationInfo = async () => {
    setLoader(true);

    getContractorOnboard().then((res) => {
      setLoader(false);

      const name = res.data.org.name;
      setprincipalEmployername(res.data.org.name);
    });
  };

  //-----
  const allCount = transactionData.reduce((acc, d) => acc + d.allCount, 0); // calculate the total count of all suppliers
  const mismatchedCount = transactionData.reduce(
    (acc, d) => acc + d.exceptionCount,
    0
  ); // calculate the total count of mismatched suppliers

  // const pieData = {
  //   legends: [
  //     {
  //       data: ["All Count", "Mismatched"],
  //       icon: "circle",
  //       formatter: (name) => {
  //         if (name === "All Count") return `All Count: ${allCount}`;
  //         else return `Mismatched: ${mismatchedCount}`;
  //       },
  //     },
  //     {
  //       data: data.map((d) => d.supplier),
  //       icon: "circle",
  //     },
  //   ],
  //   series: [
  //     {
  //       type: "pie",
  //       name: "All Count",
  //       data: [
  //         { value: allCount - mismatchedCount, name: "Matched" },
  //         { value: mismatchedCount, name: "Mismatched" },
  //       ],
  //       radius: ["0%", "40%"],
  //     },
  //     {
  //       type: "pie",
  //       name: "Mismatched",
  //       data: transactionData.map((d) => ({
  //         name: d.supplierId,
  //         value: d.exceptionCount,
  //       })),
  //       radius: ["50%", "80%"],
  //     },
  //   ],
  // };

  return (
    <>
      <Title level={4} style={{ color: 'green' }}>
        {principalEmployername}
      </Title>
      <Row gutter={[16, 16]} style={{ justifyContent: 'end' }}>
        <Col span={12} offset={12} style={{ textAlign: 'right' }}>
          <MonthPicker
            picker='month'
            style={{ marginRight: 10 }}
            onChange={handleMonthChange}
            defaultValue={moment()}
          />
          <Select
            // defaultValue="PF"
            style={{ width: 120 }}
            onChange={handleDropdown1Change}
            placeholder='PF'
          >
            <Option value='PF'>PF</Option>
            <Option value='ESI'>ESI</Option>
          </Select>
        </Col>
      </Row>
      <Divider></Divider>

      <div>
        <Row gutter={[16, 16]}>
          <Col span={18}>
            <Card title='Exception Report by Contractor'>
              <ReactEcharts
                option={{
                  tooltip: { trigger: 'axis' },
                  legend: { data: barData.legend },
                  xAxis: { data: barData.xAxis },
                  yAxis: { type: 'value' },
                  series: barData.series.map((s) => ({ ...s, type: 'bar' })),
                }}
              />
            </Card>
          </Col>
          {/* <Col span={12}>
            <Card title="Total Exceptions  by Contractor">
              <ReactEcharts
                option={{
                  tooltip: {
                    trigger: "item",
                    formatter: (params) => {
                      if (params.seriesName === "All Count") {
                        return `${params.seriesName}: ${params.name} - ${params.value}`;
                      } else {
                        return `${params.seriesName}: ${params.data.name} - ${params.data.value}`;
                      }
                    },
                  },
                  legend: pieData.legends,
                  series: pieData.series,
                }}
              />
            </Card>
          </Col> */}
        </Row>
        <Divider></Divider>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table dataSource={Employee1} columns={columns} centered />
          </Col>
          <Modal
            visible={visible}
            onCancel={handleModalCancel}
            footer={null}
            width='75%'
            maskClosable={false} // Prevent closing on outside click
          >
            <Steps current={currentStep}>
              <Step
                title={`All Employees ${reportData?.data.allCount}`}
                onClick={() => setCurrentStep(0)}
              />
              <Step
                title={`Mismatched Data ${reportData?.data.exceptionCount}`}
                onClick={() => setCurrentStep(1)}
              />
              <Step
                title={`No Match Found ${reportData?.data.noMatchCount}`}
                onClick={() => setCurrentStep(2)}
              />
            </Steps>
            <Divider></Divider>
            {currentStep === 0 && (
              <div>
                <div>
                  <Table
                    dataSource={Employee}
                    columns={modalColumns1}
                    pagination={{ pageSize: 5 }}
                    scroll={{ x: 1500 }}
                  />
                </div>
              </div>
            )}
            {currentStep === 1 && (
              <div>
                <div>
                  <Table
                    columns={modalColumns2}
                    dataSource={excepEmployeeRecords}
                    pagination={{ pageSize: 5 }}
                    scroll={{ x: 1500 }}
                  />
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div>
                <div>
                  <Table
                    dataSource={noMatchRecords}
                    columns={modalColumns3}
                    pagination={{ pageSize: 5 }}
                    // scroll={{ x: 1500 }}
                  />
                </div>
              </div>
            )}
          </Modal>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
