import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Dropdown,
  Menu,
  Typography,
  Popover,
} from 'antd';
import { DownloadOutlined, FilterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import '../styles.module.css';

const { RangePicker } = DatePicker;

const DashboardHeader = (props) => {
  const {
    filters,
    setFilters,
    downloadPDF,
    downloadCSV,
    userConfig,
    chart,
    contractorList,
    locationList,
  } = props;
  const [dateOption, setDateOption] = useState('Last Month');

  const today = dayjs();
  const [selectedDateRange, setSelectedDateRange] = useState([
    today.subtract(1, 'month').startOf('day'),
    today,
  ]);

  const dateOptions = ['Today', 'Last Week', 'Last Month', 'Custom Range'];

  const handleDateChange = (value) => {
    setDateOption(value);

    let range = [];

    if (value === 'Today') {
      range = [today, today];
    } else if (value === 'Last Week') {
      range = [today.subtract(7, 'day'), today];
    } else if (value === 'Last Month') {
      range = [today.subtract(1, 'month').startOf('day'), today];
    } else {
      range = null;
    }

    setSelectedDateRange(range);
    setFilters((prev) => ({ ...prev, dateRange: range }));
  };

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
    setFilters((prev) => ({ ...prev, dateRange: dates }));
  };

  const downloadMenu = (
    <Menu>
      <Menu.Item key='pdf' onClick={downloadPDF}>
        PDF
      </Menu.Item>
      <Menu.Item key='csv' onClick={downloadCSV}>
        CSV
      </Menu.Item>
    </Menu>
  );

  const applyFilters = () => {
    const { contractors, locations, dateRange } = filters;

    const query = {};

    if (contractors?.length > 0) {
      query['jobDetails.contractorName'] = { $in: contractors };
    }

    if (locations?.length > 0) {
      query.locationId = { $in: locations };
    }

    if (dateRange && dateRange.length === 2 && dateRange[0] != dateRange[1]) {
      query['jobDetails.doj'] = {
        $gte: new Date(dateRange[0].format('YYYY-MM-DD')).toISOString(),
        $lte: new Date(dateRange[1].format('YYYY-MM-DD')).toISOString(),
      };
    }

    if (dateRange && dateRange.length === 2 && dateRange[0] == dateRange[1]) {
      query['jobDetails.doj'] = new Date(
        dateRange[0].format('YYYY-MM-DD')
      ).toISOString();
    }

    console.log('query', query, filters);

    chart.setFilter(query);
  };

  const content = (
    <>
      {contractorList.length > 0 && (
        <Col span={24}>
          <Typography.Text style={{ fontSize: '10px' }}>
            Contractors
          </Typography.Text>
          <Row align='middle'>
            <Col flex='auto'>
              <Select
                mode='multiple'
                placeholder='Select Contractors'
                style={{ width: '100%' }}
                defaultValue={contractorList}
                options={[
                  //{ value: 'All', label: 'All' },
                  ...contractorList?.map((c) => ({
                    value: c,
                    label: c,
                  })),
                ]}
                popupClassName='custom-select-dropdown'
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, contractors: value }))
                }
                maxTagCount={2}
                maxTagPlaceholder={(omittedValues) =>
                  `+${omittedValues.length}`
                }
              />
            </Col>
          </Row>
        </Col>
      )}

      {locationList.length > 0 && (
        <Col span={24} style={{ margin: '10px 0px 20px 0px' }}>
          <Typography.Text style={{ fontSize: '10px' }}>
            Locations
          </Typography.Text>
          <Row align='middle'>
            <Col flex='auto'>
              <Select
                mode='multiple'
                placeholder='Select Locations'
                style={{ width: '100%' }}
                defaultValue={locationList}
                options={[
                  //  { value: 'All', label: 'All' },
                  ...locationList?.map((l) => ({
                    value: l,
                    label: l,
                  })),
                ]}
                popupClassName='custom-select-dropdown'
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, locations: value }))
                }
                maxTagCount={2}
                maxTagPlaceholder={(omittedValues) =>
                  `+${omittedValues.length}`
                }
              />
            </Col>
          </Row>
        </Col>
      )}

      <Col span={24}>
        <Button
          style={{ marginRight: '5px' }}
          type='primary'
          onClick={applyFilters}>
          Apply
        </Button>
      </Col>
    </>
  );

  return (
    <div
      style={{
        border: '1px solid #f0f0f0',
        padding: '8px',
        borderRadius: '4px',
        maxWidth: '378px',
      }}>
      <Row gutter={[8, 8]} align='middle' style={{ padding: '0px' }}>
        <Col span={24} style={{ padding: '0px' }}>
          <Row align='middle'>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Select
                value={dateOption}
                style={{
                  width: '110px',
                  fontSize: '10px',
                }}
                onChange={handleDateChange}
                options={dateOptions.map((option) => ({
                  value: option,
                  label: option,
                }))}
                popupClassName='custom-select-dropdown'
                dropdownStyle={{
                  fontSize: '10px',
                }}
              />
              <RangePicker
                style={{
                  marginLeft: '3px',
                  maxWidth: '186px',
                  height: '32px',
                }}
                className='custom-range-picker'
                value={selectedDateRange}
                onChange={handleDateRangeChange}
              />
              <Popover
                content={content}
                title='Filters'
                trigger='click'
                placement='bottomLeft'>
                <Button
                  style={{ marginLeft: '3px' }}
                  icon={
                    <FilterOutlined style={{ width: '13px', height: '13px' }} />
                  }
                />
              </Popover>
              <div style={{ marginLeft: '3px' }}>
                <Dropdown overlay={downloadMenu} trigger={['click']}>
                  <Button icon={<DownloadOutlined />} />
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardHeader;
