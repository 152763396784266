import React, { useEffect, useState } from 'react';
import { Steps, notification, Divider } from 'antd';
import Personaldetails from '../components/EmployeeOnboard/Personneldetails';
import Jobdetails from '../components/EmployeeOnboard/Jobdetails';
import Wagedetails from '../components/EmployeeOnboard/Wagedetails';
import Uploaddocument from '../components/EmployeeOnboard/Uploaddocument';
import Summarygeneration from '../components/EmployeeOnboard/Summarygeneration';
import createPass from '../services/employeeonboard';
import useAuth from '../hooks/useAuth';
import { Email } from '../services/auth';
import dayjs from 'dayjs';

import {
  getEmployeeList,
  searchEmployee,
  getWorkmenLimit,
  getExcelData,
} from '../services/auth';
import Quickonboarding from '../quickonboarding/quickonboarding';
// import EmployeeList from './EmployeeList';
import axios from '../services/axiosInstance';
import { logout } from '../services/auth';
import { uploadDocument as uploadDocumentToGCP } from '../services/auth';

const EmployeeOnboard = () => {
  const { user, userConfig, logout } = useAuth();
  const orgId = userConfig?.orgId;

  const [imageSrc, setImageSrc] = useState(null);
  // const [contractorCode,setContractorCode] = useState([])
  const [contractorList, setContractorList] = useState([]);
  const [jobOrderList, setJobOrderList] = useState([]);
  // Location
  const [location, setlocation] = React.useState({});
  const [idStatus, setIdStatus] = React.useState([]);
  const [masterData, setMasterData] = React.useState([]);

  useEffect(() => {
    EmployeeList();
  }, []);
  async function EmployeeList() {
    const Response = await axios.get('/org/locations');
    const Response2 = Response.data.org.locations[0].id;
    setlocation({ Response2 });
  }

  const userType = userConfig?.userType;
  const contractorId = userConfig?.supplierId;

  const checkContractorStatus = (contractorId) => {
    const contractor = idStatus.find((item) => item.id === contractorId);
    if (contractor) {
      const { status } = contractor;
      if (
        status === 'terminated' ||
        status === 'blocked' ||
        status === 'blacklisted'
      ) {
        notification.warning({
          message: `Contractor with ID ${contractorId} has status: ${status}.`,
        });

        // Logout after 5 seconds
        setTimeout(() => {
          logout();
        }, 5000);
      }
    }
  };

  useEffect(() => {
    checkContractorStatus(contractorId);
  }, [contractorId, idStatus]);

  /* eslint-disable */
  const DEFAULT_ADMIN_FORM_VALUE = {
    firstName: '',
    lastName: '',
    gender: '',
    aadharNo: '',
    fatherName: '',
    address: '',
    village: '',
    pincode: '',
    state: '',
    district: '',
    paddress: '',
    pvillage: '',
    pstate: '',
    pdistrict: '',
    ppincode: '',
    aadharNo: '',
    dob: '',
    age: '',
    mobile: '',
    locationId: '',
    status: '',
  };
  const [currentPageNo, setCurrentPageNo] = React.useState(0);
  const [personalDetails, setpersonalDetails] = React.useState(
    DEFAULT_ADMIN_FORM_VALUE
  );
  // const [personalDetails, setpersonalDetails] = React.useState();

  const [jobDetails, setjobDetails] = React.useState();
  const [wageDetails, setwageDetails] = React.useState();
  const [uploadDocument, setuploadDocument] = React.useState();

  const [Summary, setSummary] = React.useState();
  const data = {
    personalDetails: personalDetails,
    jobDetails: jobDetails,
    wageDetails: wageDetails,
    uploadDocument: uploadDocument,
    // locationId:location.Response2,
    locationId: personalDetails.locationId,
    // livePhoto: imageSrc,
  };
  data.employementType = 'Contractor';
  data.personalDetails.statusFromDate = new Date(
    data?.personalDetails.statusFromDate
  ).getTime();
  data.personalDetails.statusToDate = new Date(
    data?.personalDetails.statusToDate
  ).getTime();

  data.personalDetails.statusTerminateNoticeDate = new Date(
    data?.personalDetails.statusTerminateNoticeDate
  ).getTime();

  const base64ToBlob = (base64String, mimeType) =>{
    // Remove the base64 data URL prefix if it exists
    const base64 = base64String.split(';base64,').pop();

    // Decode the base64 string into a binary string
    const binaryString = atob(base64);

    // Create a typed array (Uint8Array) to store the binary data
    const byteArray = new Uint8Array(binaryString.length);

    // Convert the binary string into the byte array
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    // Convert the byte array into a Blob
    const blob = new Blob([byteArray], { type: mimeType });

    return blob; // This is your "buffer" in Blob form
  }

  async function submit() {
    let file = '';
    let profilePicture = '';
    let secondaryLivePhoto = ''; // To store the secondary livePhoto in case of aadhar onboarding only
    let secondaryUploadPhoto = ''; // To store the secondary uploadPhoto in case of aadhar onboarding only
    data.isFetchedFromAadhar = data?.personalDetails?.isFetchedFromAadhar;

    delete data?.jobDetails.personalDetails?.isFetchedFromAadhar;
      data.personalDetails.dob = data?.personalDetails?.dob ? dayjs.utc(new Date(data?.personalDetails?.dob)) : '';
      data.personalDetails.Dob = data?.personalDetails?.Dob ? dayjs.utc(new Date(data?.personalDetails?.Dob)) : '';



    // upload file to GCP
    if(data?.personalDetails?.livePhoto){
      const mimeType = 'image/jpeg';
      let imageBuffer = base64ToBlob(data?.personalDetails?.livePhoto, mimeType);
      file = imageBuffer; // file creation for GCP upload
    }
    else if(data?.personalDetails?.uploadPhoto){
      const mimeType = 'image/jpeg';
      let imageBuffer = base64ToBlob(data?.personalDetails?.uploadPhoto?.fileList[0]?.thumbUrl, mimeType);
      file = imageBuffer; // file creation for GCP upload
    }
    else{
      file = null;
    }
    // aadhar onboarding
    if (data?.personalDetails?.aadharPhoto) {
      const mimeType = 'image/jpeg';
      file = base64ToBlob(data?.personalDetails?.aadharPhoto, mimeType);

      // Check for secondary livePhoto or uploadPhoto
      if (data?.personalDetails?.livePhoto) {
          secondaryLivePhoto = base64ToBlob(data?.personalDetails?.livePhoto, mimeType);
      }
      if (data?.personalDetails?.uploadPhoto) {
          secondaryUploadPhoto = base64ToBlob(data?.personalDetails?.uploadPhoto?.fileList[0]?.thumbUrl, mimeType);
      }
    }
    
    if(file){
      const fileName = `${data?.personalDetails?.mobile}_${
        userConfig?.orgId
      }_${Date.now()}_profilePicture.png`;
      const filePath = `${process.env.REACT_APP_GCP_PATH}/${userConfig?.orgId}/contract_workmen/${fileName}`;
  
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', filePath);
      const res = await uploadDocumentToGCP(formData); // upload to GCP
      profilePicture = res?.data?.data?.gcsFileName;
    }

    // Upload secondary livePhoto if it exists
    let secondaryLivePhotoPath = '';
    if (secondaryLivePhoto) {
        const secondaryLivePhotoName = `${data?.personalDetails?.mobile}_${userConfig?.orgId}_${Date.now()}_livePhoto.png`;
        const secondaryLivePhotoFilePath = `${process.env.REACT_APP_GCP_PATH}/${userConfig?.orgId}/contract_workmen/${secondaryLivePhotoName}`;

        const secondaryLivePhotoFormData = new FormData();
        secondaryLivePhotoFormData.append('file', secondaryLivePhoto);
        secondaryLivePhotoFormData.append('fileName', secondaryLivePhotoFilePath);

        const secondaryLivePhotoRes = await uploadDocumentToGCP(secondaryLivePhotoFormData);
        secondaryLivePhotoPath = secondaryLivePhotoRes?.data?.data?.gcsFileName;
    }

    // Upload secondary uploadPhoto if it exists
    let secondaryUploadPhotoPath = '';
    if (secondaryUploadPhoto) {
        const secondaryUploadPhotoName = `${data?.personalDetails?.mobile}_${userConfig?.orgId}_${Date.now()}_uploadPhoto.png`;
        const secondaryUploadPhotoFilePath = `${process.env.REACT_APP_GCP_PATH}/${userConfig?.orgId}/contract_workmen/${secondaryUploadPhotoName}`;

        const secondaryUploadPhotoFormData = new FormData();
        secondaryUploadPhotoFormData.append('file', secondaryUploadPhoto);
        secondaryUploadPhotoFormData.append('fileName', secondaryUploadPhotoFilePath);

        const secondaryUploadPhotoRes = await uploadDocumentToGCP(secondaryUploadPhotoFormData);
        secondaryUploadPhotoPath = secondaryUploadPhotoRes?.data?.data?.gcsFileName;
    }

    let updatedData;
    if (data?.personalDetails?.livePhoto) {
      updatedData = {
        ...data,
        personalDetails: {
          ...data.personalDetails,
          livePhoto: null,
          uploadPhoto: null,
          livePhotoGcpPath: profilePicture,
        },
      };
    } else if (data?.personalDetails?.uploadPhoto) {
      updatedData = {
        ...data,
        personalDetails: {
          ...data.personalDetails,
          uploadPhoto: null,
          livePhoto: null,
          uploadPhotoGcpPath: profilePicture,
        },
      };
    } else if (data?.personalDetails?.aadharPhoto) {
      updatedData = {
        ...data,
        personalDetails: {
          ...data.personalDetails,
          aadharPhoto: null,
          aadharPhotoGcpPath: profilePicture,
          ...(secondaryLivePhotoPath && {
            livePhoto: null,
            livePhotoGcpPath: secondaryLivePhotoPath,
          }),
          ...(secondaryUploadPhotoPath && {
            uploadPhoto: null,
            uploadPhotoGcpPath: secondaryUploadPhotoPath,
          }),
        },
      };
    } else {
      updatedData = data;
    }
    console.log("updated data before", updatedData?.personalDetails?.Dob)
    updatedData.personalDetails.Dob = dayjs(updatedData?.personalDetails?.Dob).utc().local().startOf('day').format('YYYY-MM-DD')
    updatedData.personalDetails.dob = updatedData.personalDetails.dob ? dayjs(updatedData?.personalDetails?.dob).utc().local().startOf('day').format('YYYY-MM-DD') : ''
    updatedData.jobDetails.doj = updatedData.jobDetails.doj ? dayjs(updatedData?.jobDetails?.doj).utc().local().startOf('day').format('YYYY-MM-DD') : ''
    updatedData.jobDetails.dlExpiry = updatedData.jobDetails.dlExpiry ? dayjs(updatedData?.jobDetails?.dlExpiry).utc().local().startOf('day').format('YYYY-MM-DD') : ''

    console.log("updated data after", updatedData?.personalDetails?.Dob)

    
    createPass(updatedData)
      .then((res) => {
        notification.success({ message: res.data.message });
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    Email({
      workmenName:
        data?.personalDetails?.firstName +
        ' ' +
        data?.personalDetails?.lastName,
      // VYNApplication: process.env.REACT_APP_URL,
      VYNApplication:`${axios.defaults.baseURL.replace('/api', '')}/login`, 
      templateId: 'WORKMEN_ONBOARDED',
      orgId: userConfig.orgId,
      role: 'approver',
      level: 1,
      locationId: data?.locationId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  }

  useEffect(() => {
    // Fetch data when the component mounts
    workmenLimit();
    getExcel();
  }, []);

  const workmenLimit = async () => {
    //
    getWorkmenLimit(orgId)
      .then((res) => {
        //

        // const contractorIdsFromData = res.data.map(item => {
        //   if (item && item.addJobDetails && Array.isArray(item.addJobDetails)) {
        //     return item.addJobDetails.map(details => details?.id);
        //   }
        //   return null;
        // });

        // const flattenedContractorIds = contractorIdsFromData.flat();
        // const uniqueContractorIds = [...new Set(flattenedContractorIds)];
        // //uniquejoborders
        // // const uniqueJobOrderNumbers = [...new Set(flattenedContractorIdsAndJobOrderNumbers.map(item => item.jobOrderNumber))];

        //
        // //
        // setContractorCode(uniqueContractorIds)

        // // setData(res.data);
        // // setMaxContractorLimit(res.data.maxContractors)
        // // setlln(res.data.lln)
        const contractorIdsAndJobOrderNumbers = res.data.map((item) => {
          if (item && item.addJobDetails && Array.isArray(item.addJobDetails)) {
            return item.addJobDetails.map((details) => ({
              id: details?.id,
              jobOrderNumber: details?.jobordernumber,
            }));
          }
          return null;
        });

        // Flatten the array of objects into an array of { id, jobOrderNumber }
        const flattenedContractorIdsAndJobOrderNumbers =
          contractorIdsAndJobOrderNumbers.flat();

        // Get unique contractor IDs
        const uniqueContractorIds = [
          ...new Set(
            flattenedContractorIdsAndJobOrderNumbers.map((item) => item.id)
          ),
        ];

        // Get unique contractor IDs and there status
        const uniqueContractorIdsAndStatus = res.data.map((item) => ({
          id: item?.id,
          status: item?.status,
        }));
        setIdStatus(uniqueContractorIdsAndStatus);

        //

        // Get unique job order numbers
        const uniqueJobOrderNumbers = [
          ...new Set(
            flattenedContractorIdsAndJobOrderNumbers.map(
              (item) => item.jobOrderNumber
            )
          ),
        ];

        //
        //
        // Set state variables with unique IDs
        setContractorList(uniqueContractorIds);
        setJobOrderList(uniqueJobOrderNumbers);
      })
      .catch((error) => {
        // setLoader(false);
      });
  };

  const getExcel = async () => {
    getExcelData(orgId)
      .then((res) => {
        setMasterData(res.data.data.data);
      })
      .catch((error) => {});
  };

  const pageItems = [
    {
      key: 'personneldetails',
      title: 'Personal Details',
    },
    {
      key: 'jobdetails',
      title: 'Job Details',
    },
    {
      key: 'wagedetails',
      title: 'Wage Details',
    },
    {
      key: 'uploaddocument',
      title: 'Upload Document',
    },
    {
      key: 'summary',
      title: 'Summary Generation',
    },
  ];
  const next = (values) => {
    if (currentPageNo === 0) {
      // setpersonalDetails(values);
      setpersonalDetails((currData) => ({ ...currData, ...values }));
    }

    if (currentPageNo === 1) {
      setjobDetails(values);
    }
    if (currentPageNo === 2) {
      setwageDetails(values);
    }
    if (currentPageNo === 3) {
      setuploadDocument(values);
    }
    if (currentPageNo === 4) {
      setSummary(values);

      // submit the data
      submit(values);
      return;
    }

    setCurrentPageNo(currentPageNo + 1);
  };

  const prev = (values) => {
    if (currentPageNo === 1) {
      setjobDetails(values);
    }
    if (currentPageNo === 2) {
      setwageDetails(values);
    }
    if (currentPageNo === 3) {
      setuploadDocument(values);
    }
    if (currentPageNo === 4) {
      setSummary(values);
    }

    setCurrentPageNo(currentPageNo - 1);
  };

  //
  return (
    <div>
      <h2>Workmen Details</h2> <br />
      <br />
      <Steps current={currentPageNo} items={pageItems} /> <Divider />
      {currentPageNo === 0 && (
        <Personaldetails
          initialValues={personalDetails}
          onNext={next}
          setImageSrc={setImageSrc}
          imageSrc={imageSrc}
          masterData={masterData}
        />
      )}
      {currentPageNo === 1 && (
        <Jobdetails
          initialValues={jobDetails}
          onNext={next}
          onPrev={prev}
          jobOrderList={jobOrderList}
          contractorList={contractorList}
          masterData={masterData}
        />
      )}
      {currentPageNo === 2 && (
        <Wagedetails initialValues={wageDetails} onNext={next} onPrev={prev} />
      )}
      {currentPageNo === 3 && (
        <Uploaddocument
          initialValues={uploadDocument}
          personalDetails={personalDetails}
          onNext={next}
          onPrev={prev}
          jobDetails={jobDetails}
        />
      )}
      {currentPageNo === 4 && (
        <Summarygeneration
          initialValues={personalDetails}
          onNext={next}
          onPrev={prev}
          jobDetails={jobDetails}
          wageDetails={wageDetails}
        />
      )}
    </div>
  );
};

export default EmployeeOnboard;
