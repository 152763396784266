import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { Button, Col, Row, Typography } from 'antd';
import { getAllReports, getDetails } from '../../../services/auth';
import Chart from './Chart';
import { getSupplier } from '../../../services/supplier';

const Dashboard = () => {
  const { user, userConfig } = useAuth();
  const [chartsData, setChartData] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [contractorList, setContractorList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const getFilters = () => {
    let filter = '{}';
    if (['admin', 'approver'].includes(userConfig.role)) {
      // #dev
      filter = `{%22locationId%22:%22${'RNW'}%22}`;
      // #prod
      // filter = `{%22locationId%22:%22${userConfig.location || userConfig.locationId}%22}`
    } else if (['ir', 'supervisor'].includes(userConfig.role)) {
      // #dev
      filter = `{%22createdBy%22:%22${'120189'}%22}`;
      // #prod
      // filter = `{%22createdBy%22:%22${userConfig.userid}%22}`
    }

    return filter;
  };

  const handleEditClick = () => {
    window.open(userConfig?.editLink, '_blank');
  };

  useEffect(() => {
    getChartsData();
    fetchData();
  }, []);

  const getChartsData = async () => {
    const response = await getAllReports();
    setChartData(
      response?.data?.data?.filter((chart) => chart.type == 'DASHBOARD')
    );
    getPermissions(userConfig.orgId);
  };

  const fetchData = async () => {
    try {
      const res = await getSupplier(userConfig.orgId);
      const list = [];
      const locationId = userConfig.location || userConfig.locationId;

      if (locationId == null) {
        // case of super admin
        const locationMap = {};
        res.data?.suppliers?.map((c) => {
          list.push(c.name);
          locationMap[c.location] = 1;
        });
        setLocationList(Object.keys(locationMap));
      } else if (locationId) {
        res.data?.suppliers?.map((c) => {
          if (c.location == locationId) {
            list.push(c.name);
          }
        });
        setLocationList([locationId]);
      }
      setContractorList(list);
    } catch (error) {
      console.error('Error fetching contractor name:', error);
    }
  };

  const getPermissions = (orgId) => {
    getDetails(orgId)
      .then((response) => {
        const filteredData = response?.data?.filter(
          (i) => i.selectedRole?.toLowerCase() == userConfig.role.toLowerCase()
        );
        if (filteredData.length > 0) {
          setPermissions(filteredData[0]?.permissions?.Reports_And_Dashboard);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  return (
    <>
      <Row>
        <Col span={24} style={{ textAlign: 'left' }}>
          <Typography.Text
            strong
            style={{ fontSize: '16px', padding: '0px 0px 20px 10px' }}>
            Dashboard
          </Typography.Text>
          {/* <Button
            disabled={!userConfig?.editLink}
            type='primary'
            onClick={handleEditClick}>
            Edit
          </Button> */}
        </Col>
      </Row>
      <Row>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}>
          {chartsData.length > 0 &&
            locationList.length > 0 &&
            chartsData?.map((c) => (
              <div
                key={`chart-${c.chartId}`}
                style={{ margin: '10px', flex: '1 1 calc(33.333% - 20px)' }}>
                <Chart
                  chartData={c}
                  permissions={permissions}
                  userConfig={userConfig}
                  contractorList={contractorList}
                  locationList={locationList}
                />
              </div>
            ))}
        </div>
      </Row>
    </>
  );
};

export default Dashboard;
